import React from "react";


export default function Conditional(props) {
  const {
    show = true, // Renders the children or not
    display = null, // Display the rendered children or not
  } = props;

  // No rendering of the children at all
  if (show !== true) return null;

  // No conditional parent div is display isn't a boolean
  if (display === null) return props.children;

  const style = {
    display: display ? "block" : "none",
  }

  return <div style={style}>{props.children}</div>;
}
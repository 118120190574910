import React from "react";
import styles from "./Divider.module.scss";

const SPACES = {
  SECTIONS: styles.sections,
  BIG: styles.big,
  NORMAL: styles.normal,
  SMALL: styles.small,
  SMALLER: styles.smaller,
  TINY: styles.tiny,
}

// When a divider is first or last, the border and margin bottom are not shown
function Divider(props) {
  const classes = [styles.module, props.className, props.spaceAround, "divider"];
  if (props.firstOrLast) {
    classes.push(styles.firstOrLast);
  }

  if (props.children) {
    return (
      <div className={styles.splitLine}>
        <div className={classes.join(" ")} style={props.style}></div>
        {props.children}
        <div className={classes.join(" ")} style={props.style}></div>
      </div>
    );
  }

  return (
    <div className={classes.join(" ")} style={props.style}></div>
  );
}

Divider.defaultProps = {
  spaceAround: SPACES.NORMAL,
  firstOrLast: false,
}

Divider.SPACES = SPACES;

export default Divider;

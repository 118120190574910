import {createSubmitRequestTypes} from "./helpers";

export const SHOW_ANONYMOUS_CART_NOT_FOUND_WINDOW = "AC:SHOW_ANONYMOUS_CART_NOT_FOUND_WINDOW";
export const showAnonymousCartNotFoundWindow = (cartId) => ({type: SHOW_ANONYMOUS_CART_NOT_FOUND_WINDOW, cartId});

export const BEGIN_ANONYMOUS_CHECKOUT_SAGA = "AC:BEGIN_ANONYMOUS_CHECKOUT_SAGA"
export const beginAnonymousCheckoutSaga = (cartId, accountId, countryCode, authenticated, noAnonymousCheckout = false, fromAnonymousCheckout = false, maybeEmailAddress = "") => ({type: BEGIN_ANONYMOUS_CHECKOUT_SAGA, cartId, accountId, countryCode, authenticated, noAnonymousCheckout, fromAnonymousCheckout, maybeEmailAddress});

export const ANONYMOUS_CHECKOUT_ALREADY_COMPLETED = "AC:ANONYMOUS_CHECKOUT_ALREADY_COMPLETED";
export const anonymousCheckoutAlreadyCompleted = (cartId, accountId) => ({type: ANONYMOUS_CHECKOUT_ALREADY_COMPLETED, cartId, accountId});

export const SIGN_IN = createSubmitRequestTypes("AC:SIGN_IN");
export const REQUEST_SIGN_IN = "AC:REQUEST_SIGN_IN";
export const requestSignIn = () => ({type: REQUEST_SIGN_IN});
export const signedIn = (params) => ({type: SIGN_IN.SUCCESS, params});
export const signInCancelled = () => ({type: SIGN_IN.CANCEL});
export const signInFailed = () => ({type: SIGN_IN.FAILED});

export const REQUEST_REGISTER = "AC:REQUEST_REGISTER";
export const REGISTER = createSubmitRequestTypes("AC:REGISTER");
export const registerCancelled = () => ({type: REGISTER.CANCEL});
export const requestRegister = () => ({type: REQUEST_REGISTER});
export const registered = (onboardingId, email, password) => ({type: REGISTER.SUCCESS, onboardingId, email, password});

export const SELECT_ACCOUNT = createSubmitRequestTypes("AC:SELECT_ACCOUNT");
export const REQUEST_SELECT_ACCOUNT = "AC:REQUEST_SELECT_ACCOUNT";
export const SELECT_ACCOUNT_NO_APPLICABLE_ACCOUNTS = "AC:SELECT_ACCOUNT_NO_APPLICABLE_ACCOUNTS";
export const requestSelectAccount = () => ({type: REQUEST_SELECT_ACCOUNT});
export const selectedAccount = (accountId) => ({type: SELECT_ACCOUNT.SUCCESS, accountId});
export const selectAccountFailed = () => ({type: SELECT_ACCOUNT.FAILED});
export const reportNoApplicableAccounts = () => ({type: SELECT_ACCOUNT_NO_APPLICABLE_ACCOUNTS});

export const CREATE_ACCOUNT = createSubmitRequestTypes("AC:CREATE_ACCOUNT");
export const ACCOUNT_READY = createSubmitRequestTypes("AC:ACCOUNT_READY");
export const accountCreated = (accountId) => ({type: CREATE_ACCOUNT.SUCCESS, accountId});
export const accountCreationFailed = () => ({type: CREATE_ACCOUNT.FAILED});
export const accountReady = (accountId) => ({type: ACCOUNT_READY.SUCCESS, accountId});
export const accountReadyFailed = (accountId) => ({type: ACCOUNT_READY.FAILURE, accountId});

export const POPULATE_CART = createSubmitRequestTypes("AC:POPULATE_CART");
export const cartPopulated = () => ({type: POPULATE_CART.SUCCESS});
export const cartPopulationFailed = () => ({type: POPULATE_CART.FAILED});

export const CHECKOUT_CART = createSubmitRequestTypes("AC:CHECKOUT_CART");
export const checkoutStarted = (cartId, accountId, orderId) => ({type: CHECKOUT_CART.SUCCESS, cartId, accountId, orderId});
export const checkoutFailed = () => ({type: CHECKOUT_CART.FAILED});

export const CLOSE_ERROR_WINDOW = "AC:CLOSE_ERROR_WINDOW";
export const closeErrorWindow = () => ({type: CLOSE_ERROR_WINDOW});
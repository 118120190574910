import React, {useState} from "react";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import FooterActions from "../../shared/FooterActions";
import Form from "../forms/RegisterPasswordForm";
import {validatePassword} from "../../lib/Validators";
import {Map} from "immutable";
import { useTranslation } from 'react-i18next';
import useQueryMap from "../../hooks/useQueryMap";
import Navigation from "../../lib/Navigation";
import gql from "graphql-tag";
import { createErrors, createValidationErrors } from "../../lib/ErrorFormatter";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";


function validateForm(values) {
  let errors = Map();
  errors = validatePassword(errors, values, "password");

  return errors.toJS();
}


function RegisterPassword(props) {
  const { t } = useTranslation("auth");
  const [errors, setErrors] = useState([]);
  const query = useQueryMap();
  const customerId = query.get("id")

  if (!customerId) {
    // Invalid use of this page => redirect to sign in
    Navigation.signIn()
  }

  function handleSignIn() {
    Navigation.signIn()
  }

  function handleSubmit(values) {
    let updatedValues = values.set("customerId", customerId)

    return props.anonymousCheckoutRegisterPassword(updatedValues.toJS())
      .then((response) => {
        if (response.data.anonymousCheckoutRegisterPassword.errors.length <= 0) {
          // Signin
          props.onPasswordRegistration(response.data.anonymousCheckoutRegisterPassword.email, values.get("password"))
        } else {
          let errors = createValidationErrors(response.data.anonymousCheckoutRegisterPassword.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });
  }

  return (
    <div className="RegisterPassword view">
      <div className="inner">
        <React.Fragment>
          <h4 className="sg-text-large u-bottom-margin-short">{t("register_password.title")}</h4>
          <p className="sg-text-normal">{t("register_password.subtitle")}</p>
          <Form 
            errors={errors}
            validate={validateForm}
            onSubmit={handleSubmit}
            onSignIn={handleSignIn}
          />

          <div className={"u-centered sg-font-bold"}>
            <FooterActions
              showSupport={props.showSupport} 
              darkBackground={true}
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

const REGISTER_PASSWORD = gql`
  mutation anonymousCheckoutRegisterPassword($data: AnonymousCheckoutInformationInput!) {
    anonymousCheckoutRegisterPassword(input: $data) {
      email
      errors { key message }
    }
  }
`;

const withMutation = compose(
  graphql(REGISTER_PASSWORD, {
    props: ({ mutate }) => ({
      anonymousCheckoutRegisterPassword: (data) => mutate({
        variables: { data: data }
      })
    })
  }) 
);

export default connect(null, {showSupport})(withMutation(RegisterPassword));
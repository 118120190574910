import React, {useEffect, useState} from "react";
import {graphql} from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import ShopLayout from "../shop/ShopLayout";
import Conditional from "../shared/Conditional";
import { fromJS, Map } from "immutable";
import { PlaceOrderFixedPanel } from "../shop/views/Checkout/PlaceOrderFixedPanel";
import { Breadcrumb } from "./views/Breadcrumb";
import Navigation, { buildWebsiteLink } from "../lib/Navigation";
import styles from "./AnonymousCheckoutPage.module.scss";
import withCmsProductTitles from "../hocs/withCmsProductTitles";
import useResizeListener from "../hooks/useResizeListener";
import { requestShowOrderStatus } from "../actions/ShopActions";
import { useDispatch } from "react-redux";
import { MobileOrderSummary } from "./views/MobileOrderSummary";
import AnonymousOrderVersionMonitor from "./components/AnonymousOrderVersionMonitor";
import SaveShippingAddressWhenReady from "./views/stepInformation/SaveShippingAddressWhenReady";
import { validateShippingAddress } from "../lib/AddressValidation";
import BillingSetupPaymentCredentials from "./BillingSetupPaymentCredentials";
import { INFORMATION_ANCHORS } from "./views/stepInformation/StepInformation";
import FakeMobileHeader from "./views/FakeMobileHeader";
import { useTranslation } from "react-i18next";
import Button from "../shared/components/Button";
import { generatePurchaseSummary } from "../lib/StatsSummary";
import { recordPurchaseCompleted } from "../lib/Analytics";
import WaterDisc from "../shared/rive/WaterDisc";


export const CHECKOUT_STEPS = {
  // Steps reachable from URL
  STEP_INFORMATION: "1",
  STEP_PAYMENT: "2",
}

// Sales order status, from the system
const ORDER_STATUS = {
  DEFAULT: "",
  STARTED: "started",
  PAID: "paid",
  BILLED: "billed",
  FAILED: "failed",
  CHECKOUT_FAILED_INSUFFICIENT_STOCK: "checkout_failed_insufficient_stock",
  CHECKOUT_FAILED: "checkout_failed",
}

const DESKTOP_CUTOFF = 700;


function AnonymousCheckoutPage(props) {
  const { t } = useTranslation("checkout");
  const {
    cartId,
    step = CHECKOUT_STEPS.STEP_INFORMATION,
    cmsDataProductTitles,
    submitting = false,
  } = props;

  const {
    loading,
    error: queryErrors,
    anonymousCheckoutInfo: checkoutInfoData,
  } = props.data;

  const dispatch = useDispatch();

  const { width } = useResizeListener(window.document.body);
  const [anonymousCheckoutInfo, setAnonymousCheckoutInfo] = useState(Map());
  const [savedInformationData, setSavedInformationData] = useState(Map());
  const [orderStatus, setOrderStatus] = useState("");
  const [currentOrderStatus, setCurrentOrderStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Allow activating children forms submissions
  const [stepPaymentStripeStatus, setStepPaymentStripeStatus] = useState("");

  // When payment is processing
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  useEffect(() => {
    if (checkoutInfoData) {
      if (checkoutInfoData.status) {
        setOrderStatus(checkoutInfoData.status);
      }

      const info = prepareCheckoutInfo(checkoutInfoData, savedInformationData)
      
      setAnonymousCheckoutInfo(info);
    } else if (!loading) {
      // Only when the checkout is done will the checkout info be undefined
      Navigation.signUp();
    }
  }, [checkoutInfoData]);

  function updateAddresses(shippingAddress, billingAddress) {
    setAnonymousCheckoutInfo(anonymousCheckoutInfo.set("shippingAddress", shippingAddress).set("billingAddress", billingAddress));
  }

  if (!loading && queryErrors && queryErrors.message) {
    if (queryErrors.message.includes("not_found")) {
      // this sales order has been processed already so we redirect to signIn
      // unless the client has done the action without refreshing the page.
      if (paymentProcessing) {
        if (orderStatus !== ORDER_STATUS.BILLED) {
          // final screen
          setOrderStatus(ORDER_STATUS.BILLED);
        }
      } else {
        // User went back to the checkout url or refreshed
        Navigation.signIn();
      }
    } else if (orderStatus !== ORDER_STATUS.FAILED) {
      setErrorMessage(queryErrors.message);
      setOrderStatus(ORDER_STATUS.FAILED);
    }
  }

  function handleRefetch() {
    props.data.refetch();
  }

  const checkoutOrderId = anonymousCheckoutInfo.get("orderId");
  const checkoutAccountId = anonymousCheckoutInfo.get("accountId");
  const checkoutSummaryData = loading ? anonymousCheckoutInfo.set("isPreparing", true) : anonymousCheckoutInfo;

  function gotoCart() {
    buildWebsiteCartLink(anonymousCheckoutInfo);
  }
  function gotoInformationStep(anchor) {
    Navigation.gotoAnonymousCheckout(cartId, CHECKOUT_STEPS.STEP_INFORMATION, anchor);
  }
  function gotoPaymentStep(updatedCartData = Map()) {
    // By providing updated cart data, we can update parts of the cart without
    // having to wait for the system.
    if (updatedCartData.size > 0) {
      setAnonymousCheckoutInfo(anonymousCheckoutInfo.mergeDeep(updatedCartData));
      setSavedInformationData(updatedCartData);
    }

    if (canGoToPaymentStep(anonymousCheckoutInfo) || canGoToPaymentStep(updatedCartData)) {
      // Already had the right data, or the system just returned the right data
      Navigation.gotoAnonymousCheckout(cartId, CHECKOUT_STEPS.STEP_PAYMENT);
    } else {
      // Should never happen, as either the system returns an error and this
      // function doesn't get called, or the system returns the account ID.
      console.error("No account ID received from the system");
    }
  }

  const canClickOnPlaceOrder = !submitting && !!anonymousCheckoutInfo.get("canPay")
    && !!anonymousCheckoutInfo.get("total", "--") !== "--"
    && !paymentProcessing && stepPaymentStripeStatus !== "submit";

  const checkoutReadyForPayment = anonymousCheckoutInfo.get("canPay") && !!anonymousCheckoutInfo.get("billingPaymentMethod");

  // Called on click on the make payment button (whether in step 2 or in the fixed side panel in desktop)
  function handlePayment() {
    if (checkoutReadyForPayment) {
      // Doesn't need to resubmit payment and billing info
      setStepPaymentStripeStatus("success");
    } else if (step === CHECKOUT_STEPS.STEP_PAYMENT) {
      // Submit payment and billing info
      setStepPaymentStripeStatus("submit");
    }
  }

  useEffect(() => {
    // Stripe status and billing status are updated whenever the place order
    // button is pressed and those 2 forms submit themselves. If both complete
    // successfully, we can place the order.
    if (checkoutReadyForPayment && stepPaymentStripeStatus === "success") {
      setPaymentProcessing(true);

      // Display the stand by animation
      setOrderStatus(ORDER_STATUS.STARTED)

      const values = {
        anonymousCartId: cartId,
      }
      
      props.anonymousCheckoutPlaceOrder(values)
        .then((response) => {
          if (response.data.anonymousCheckoutPlaceOrder.errors.length <= 0) {
            // Everything OK, the user needs to wait until the process is complete
          } else {
            console.warn("ORDER ERROR", response.data.anonymousCheckoutPlaceOrder.errors);
            setPaymentProcessing(false);
            setOrderStatus(ORDER_STATUS.FAILED)
            setErrorMessage("unknown");
          }
        })
        .catch((err) => {
          console.warn("ORDER ERROR!!", err);
          setPaymentProcessing(false);
          setOrderStatus(ORDER_STATUS.FAILED)
          setErrorMessage("unknown");
        });
    }
  }, [stepPaymentStripeStatus, checkoutReadyForPayment]);

  // Handle the windows appearing depending on the current order status
  useEffect(() => {
    if (orderStatus === currentOrderStatus) return;

    // Status got updated
    setCurrentOrderStatus(orderStatus);

    const goBackToCartButton = (
      <Button label={t("back_to_cart")}
        onClick={gotoCart}
      />
    );

    switch (orderStatus) {
      case ORDER_STATUS.STARTED:
        dispatch(requestShowOrderStatus(checkoutAccountId, checkoutOrderId, "submitting", "", "", true, anonymousCheckoutInfo.get("onboardingId")));
        break;
      case ORDER_STATUS.PAID:
      case ORDER_STATUS.BILLED:
        const updatedProducts = anonymousCheckoutInfo.get("products").map((product) => {
          return product.set("productTitle", cmsDataProductTitles.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
        });
        const summary = generatePurchaseSummary(
          anonymousCheckoutInfo.set("products", updatedProducts), 
          anonymousCheckoutInfo.get("invoiceNr"), 
          "instashop"
        );
        recordPurchaseCompleted(summary.toJS());
        dispatch(requestShowOrderStatus(checkoutAccountId, checkoutOrderId, "success", "", anonymousCheckoutInfo.get("invoiceNr"), true, anonymousCheckoutInfo.get("onboardingId")));
        break;
      case ORDER_STATUS.CHECKOUT_FAILED_INSUFFICIENT_STOCK:
        gotoInformationStep(INFORMATION_ANCHORS.SHIPPING_ADDRESS);
        dispatch(requestShowOrderStatus(checkoutAccountId, checkoutOrderId, "error", t("insufficient_stock.message_generic"), anonymousCheckoutInfo.get("invoiceNr"), true, anonymousCheckoutInfo.get("onboardingId"), goBackToCartButton));
        break;
      case ORDER_STATUS.FAILED:
      case ORDER_STATUS.CHECKOUT_FAILED:
        setPaymentProcessing(false);
        dispatch(requestShowOrderStatus(checkoutAccountId, checkoutOrderId, "error", errorMessage, "", true, anonymousCheckoutInfo.get("onboardingId"), goBackToCartButton));
        break;
    }
  }, [orderStatus]);

  function handleStripeSuccess() {
    // Stripe add payment method doesn't increase the checkout version so we refetch here
    setStepPaymentStripeStatus("success");
    handleRefetch();
  }

  const contentClasses = [
    styles.content,
    width > DESKTOP_CUTOFF ? styles.desktop : styles.mobile,
  ].join(" ");

  return (
    <ShopLayout className={styles.module}
      showHeader={false} showFooter={false} loading={loading}
      noWhiteSpaceAround={true}>
      <FakeMobileHeader show={width < DESKTOP_CUTOFF} gotoCart={gotoCart} products={anonymousCheckoutInfo.get("products")} />
      <Conditional show={!!anonymousCheckoutInfo.get("onboardingId")}>
        {/* Order version monitor requires an orderId (available after user reaches step 2) */}
        <AnonymousOrderVersionMonitor
          anonymousCartId={cartId}
          onChange={() => handleRefetch()}
          version={anonymousCheckoutInfo.get("version", 0)}
        />
      </Conditional>
      <SaveShippingAddressWhenReady
        anonymousCheckoutInfo={anonymousCheckoutInfo}
        savedInformationData={savedInformationData}
      />
      <div className={styles.inner}>
        <div className={contentClasses}>
          <Conditional show={width <= DESKTOP_CUTOFF}>
            <MobileOrderSummary
              checkoutData={checkoutSummaryData}
              cmsDataProductTitles={cmsDataProductTitles}
            />
          </Conditional>
          <div className={styles.sections}>
            <div className={styles.sectionsInner}>
              <Breadcrumb
                currentStep={step} step2Active={canGoToPaymentStep(anonymousCheckoutInfo)}
                gotoCart={gotoCart} gotoInformationStep={gotoInformationStep} gotoPaymentStep={gotoPaymentStep}
              />
              <BillingSetupPaymentCredentials step={step}
                width={width}
                anonymousCartId={cartId}
                anonymousCheckoutInfo={anonymousCheckoutInfo}
                gotoPaymentStep={gotoPaymentStep}
                gotoCart={gotoCart}
                desktopCutoff={DESKTOP_CUTOFF}
                onPlaceOrder={handlePayment}
                canClickOnPlaceOrder={canClickOnPlaceOrder}
                gotoInformationStep={gotoInformationStep}
                updateAddresses={updateAddresses}
                onReload={handleReload}
                // Stripe
                stripeStatus={stepPaymentStripeStatus}
                onStripeSuccess={handleStripeSuccess}
                onStripeError={() => setStepPaymentStripeStatus("error")}
              />
            </div>
          </div>
          <Conditional show={width > DESKTOP_CUTOFF}>
            {/* Same panel that is visible in normal checkout */}
            <PlaceOrderFixedPanel
              checkoutData={checkoutSummaryData}
              cmsDataProductTitles={cmsDataProductTitles}
              canPay={canClickOnPlaceOrder}
              disablePromoCode={true}
              onPlaceOrder={handlePayment}
              onReload={handleReload}
            />
          </Conditional>
        </div>
        <Conditional display={false}>
          {/* Make sure the WaterDisc anim is preloaded before the user places the order */}
          <WaterDisc />
        </Conditional>
      </div>
    </ShopLayout>
  );
}

function handleReload() {
  window.location.reload();
}

function canGoToPaymentStep(anonymousCheckoutInfo = Map()) {
  // Onboarding id is defined when the user saves his information and the
  // system starts the onboarding process for him.
  // We don't wait for the onboarding process to finish as it can take quite
  // some time, so we will show the user as much information as we can.
  return !!anonymousCheckoutInfo.get("onboardingId");
}

function buildWebsiteCartLink(anonymousCheckoutInfo = Map()) {
  const countryCode = anonymousCheckoutInfo.get("countryCode", "us");

  window.location.href = buildWebsiteLink(null, `/shop/${countryCode.toLowerCase()}/cart`);
}

function prepareCheckoutInfo(checkoutInfoData, savedInformationData = Map()) {
  // Update anonymousCheckoutInfo whenever new data comes from the system
  let info = fromJS(checkoutInfoData);
  
  const isNewShippingAddressValid = !!info.get("shippingAddress")
    && validateShippingAddress(info.get("shippingAddress")).size === 0;
  const isCurrentShippingAddressValid = !!savedInformationData.get("shippingAddress")
    && validateShippingAddress(savedInformationData.get("shippingAddress")).size === 0;

  if (!isNewShippingAddressValid && isCurrentShippingAddressValid) {
    // Current version could be better if the system hasn't assigned the shipping address yet
    info = info.set("shippingAddress", savedInformationData.get("shippingAddress"))
  }

  return info;
}

const ANONYMOUS_CART = gql`
  query anonymousCheckoutInfo($anonymousCartId: ID!) {
    anonymousCheckoutInfo(anonymousCartId: $anonymousCartId) {
      cartId
      countryCode
      onboardingId
      marketingAccepted
      version
      orderId
      accountId
      shopId
      shopName
      currency
      shippingAddress {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
      invoiceNr
      billingStatus
      billingError
      billingAttempt
      billingDetailsStatus
      billingAddress {
        name
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
      shippingAddressStatus
      subtotalProducts
      subtotalBeforeTaxes
      subtotalTaxes
      subscriptionFrequencyWeeks
      total
      canPay
      canChangeAddress
      isPreparing
      expired
      status
      discount
      promoCode
      promoCodeApplied
      products {
        productSku
        productTitle
        quantity
        subscription
        unitPrice
        unitDiscount
        total
        currency
      }
      billingPaymentMethod{
        paymentMethodId
        type
        cardBrand
        cardExpYear
        cardExpMonth
        cardLast4
        default
        addedAt
      }
    }
  }
`;


const PLACE_ORDER = gql`
  mutation anonymousCheckoutPlaceOrder($data: AnonymousCheckoutPlaceOrderInput!) {
    anonymousCheckoutPlaceOrder(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(ANONYMOUS_CART, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        anonymousCartId: props.cartId
      }
    })
  }),
  graphql(PLACE_ORDER, {
    props: ({mutate}) => ({
      anonymousCheckoutPlaceOrder: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
)

export default withCmsProductTitles(withQueries(AnonymousCheckoutPage));

import React, { useState } from "react";
import {graphql} from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Conditional from "../shared/Conditional";
import StepInformation from "./views/stepInformation/StepInformation";
import StepPayment from "./views/stepPayment/StepPayment";
import { CHECKOUT_STEPS } from "./AnonymousCheckoutPage";
import { Trans } from "react-i18next";
import Button from "../shared/components/Button";


function BillingSetupPaymentCredentials(props) {
  const {
    width,
    step,
    anonymousCheckoutInfo,
    gotoPaymentStep,
    gotoCart,
    desktopCutoff,
    onPlaceOrder,
    canClickOnPlaceOrder,
    gotoInformationStep,
    updateAddresses,
    onReload,
    // Stripe
    stripeStatus,
    onStripeSuccess,
    onStripeError,
    // Billing address
    billingStatus,
    onBillingSuccess,
    onBillingError,
  } = props;

  const [stepInformationStatus, setStepInformationStatus] = useState("");

  const stripeClientSecret = props.data?.anonymousBillingSetupPaymentCredentials?.clientSecret;

  return (
    <>
      <Conditional display={step === CHECKOUT_STEPS.STEP_INFORMATION}>
        <StepInformation {...props}
          anonymousCheckoutInfo={anonymousCheckoutInfo}
          stripeClientSecret={stripeClientSecret}
          onSignIn={() => {}}
          gotoNextStep={gotoPaymentStep}
          gotoPreviousStep={gotoCart}
          informationStatus={stepInformationStatus}
          updateAddresses={updateAddresses}
          onInformationSuccess={() => setStepInformationStatus("success")}
          onInformationError={() => setStepInformationStatus("error")}
        />
      </Conditional>
      <Conditional display={step === CHECKOUT_STEPS.STEP_PAYMENT}>
        <StepPayment {...props}
          anonymousCheckoutInfo={anonymousCheckoutInfo}
          gotoNextStep={width <= desktopCutoff ? onPlaceOrder : null}
          canPay={canClickOnPlaceOrder}
          gotoPreviousStep={gotoInformationStep}
          // Stripe
          stripeClientSecret={stripeClientSecret}
          stripeStatus={stripeStatus}
          onStripeSuccess={onStripeSuccess}
          onStripeError={onStripeError}
          // Billing
          billingStatus={billingStatus}
          onBillingSuccess={onBillingSuccess}
          onBillingError={onBillingError}
        />
      </Conditional>
      <Conditional show={width < desktopCutoff}>
        <p className="sg-text-small" style={{marginInline: "auto", textAlign: "center", marginTop: "20px"}}>
          <Trans
            i18nKey="checkout:summary.having_problems"
            components={{
              a: <Button link={true} onClick={onReload} />
            }}
          />
        </p>
      </Conditional>
    </>
  );
}

const ANONYMOUS_PAYMENT_SECRET = gql`
  query anonymousCheckoutInfo($anonymousCartId: ID!) {
    anonymousBillingSetupPaymentCredentials(anonymousCartId: $anonymousCartId) {
      clientSecret
    }
  }
`;

const withQueries = compose(
  graphql(ANONYMOUS_PAYMENT_SECRET, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        anonymousCartId: props.anonymousCartId
      }
    })
  }),
)

export default withQueries(BillingSetupPaymentCredentials);

import { fromJS, Map } from "immutable";
import { getCountryName } from "./CountriesAndStates";
import { validateShippingAddress } from "./AddressValidation";
import { getCompletePhoneNrOrNothing } from "./TelephoneNormalizer";
import { noop } from "lodash";


export function stripeElementsOptions(clientSecret, locale, noIntent) {
  if (!locale) {
    console.error("Missing mandatory fields for Stripe <Elements />");
    return {}
  }

  // Specific to no intent setup (for express checkout)
  const conditionnalFields = noIntent ? {
    mode: "setup", // mode "payment" and "subscriptions" are also available but would require knowing the amount
    currency: "eur", // mandatory for payment mode
    setupFutureUsage: "off_session", // "on_session" is also available
  } : {
    // Client secret is mandatory except from AuthenticatePayment
    // but also cannot be set if a mode is defined (payment, setup, subscription)
    clientSecret: clientSecret,
  };

  const options = {
    // Mandatory fields
    locale: locale,
    clientSecret: clientSecret,
    // ...conditionnalFields,
    // Customisation
    loader: "always",
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: '"AbelPro", Arial, sans-serif',
        fontSizeBase: "18px",
        borderRadius: '4px',
        colorBackground: '#fff',
        colorPrimary: "#005083", // --primary-blue-700
        colorText: "#141414", // --grey-900
        colorDanger: "#d42b2b", // --accent-red-600
        colorTextPlaceholder: "#c2c2c2", // --grey-300
        focusOutline: "none",
        focusBoxShadow: "#e7eef5", // --primary-blue-200
      },
      rules: {
        '.AccordionItem': {
          boxShadow: 'none',
          border: 'none',
          paddingLeft: '0',
          paddingRight: '0',
        },
        '.Block': {
          boxShadow: 'none',
        },
        '.Label': {
          fontSize: "14px",
          lineHeight: "18px",
          marginBottom: "4px",
        },
        '.Input': {
          outline: "none",
          boxShadow: 'none',
          border: "1px solid #999", // var(--grey-400)
        },
        '.Input:focus': {
          borderColor: "#005083", // --primary-blue-700
          boxShadow: "0 0 0 5px #e7eef5", // --primary-blue-200
          color: "#005083", // --primary-blue-700
        },
        '.Input--invalid': {
          borderColor: "#d42b2b", // --accent-red-600
          boxShadow: "0 0 0 5px rgba(255, 233, 230, 0.7)",
        },
        '.Input--invalid:focus': {
          boxShadow: "0 0 0 5px #e7eef5", // --primary-blue-200
        },
        '.Error': {
          // Error text below inputs
          marginTop: "8px",
          fontSize: "14px",
        },
      },
    },
  }

  // console.log("stripeElementsOptions", options)
  return options;
}

function getPaymentMethodBrand(paymentMethod, fallbackBrand) {
  const brand = paymentMethod?.card?.wallet?.type || paymentMethod?.card?.brand || "";

  let result;

  switch (brand) {
    case "":
      result = fallbackBrand;
      break;
    
    case "amex":
      result = "American Express";
      break;
    
    case "diners":
      result = "Diners Club";
      break;

    case "jcb":
      result = "JCB";
      break;

    case "unionpay":
      result = "UnionPay";
      break;
    
    case "apple_pay":
      result = "Apple Pay";
      break;

    case "google_pay":
      result = "Google Pay";
      break;

    default:
      result = brand.charAt(0).toUpperCase() + brand.slice(1);
  }

  return result;
}

export function extractAddressesFromExpressCheckoutData(stripeInfos, accountHolder = Map()) {
  // Stripe infos vary in shape depending on the payment method and how the
  // user configured his wallet. Any error here would lead to a processing
  // error and stripe cancelling the express payment.
  try {
    // Shipping address can be made optional for the express checkout
    const { billingDetails, shippingAddress } = stripeInfos;

    const defaultName = billingDetails.name || accountHolder.get("name");
    const defaultPhone = getCompletePhoneNrOrNothing(billingDetails.phone) || accountHolder.get("phone");
    const defaultEmail = billingDetails.email || accountHolder.get("email");

    // Might be absent or incomplete
    const cleanShippingAddress = convertStripeAddressToShipping(shippingAddress?.address, {
      name: shippingAddress?.name || defaultName,
      phone: getCompletePhoneNrOrNothing(shippingAddress?.phone) || defaultPhone,
      email: shippingAddress?.email || defaultEmail,
    });

    // Must be present, though it could be incomplete
    // We set the same fields as for a shipping address as we could use it to
    // replace a missing shipping address
    const cleanBillingAddress = convertStripeAddressToShipping(billingDetails.address, {
      name: defaultName,
      phone: defaultPhone,
      email: defaultEmail,
    });

    const isShippingAddressValid = validateShippingAddress(cleanShippingAddress).size === 0;

    return {
      shippingAddress: isShippingAddressValid ? cleanShippingAddress : cleanBillingAddress,
      // Even if the billing address is not valid either, it's better than nothing
      // We can remove shipping related fields as those are not needed for billing
      billingAddress: cleanBillingAddress.delete("addressCountryName").delete("email").delete("phoneNr").delete("type"),
      cardBrand: getPaymentMethodBrand(stripeInfos, "wallet"),
    }
  } catch (e) {
    console.warn("Exception in express checkout", e)

    return {
      shippingAddress: fromJS({}),
      billingAddress: fromJS({}),
      cardBrand: "",
    }
  }
}

function convertStripeAddressToShipping(stripeAddress, optional) {
  if (!stripeAddress) return fromJS({})

  // Code must be uppercase
  const countryCode = (stripeAddress.country || "").toUpperCase();
  const countryName = countryCode ? getCountryName(countryCode) : ""; 

  const address = {
    addressAddressLine1: stripeAddress.line1 || "",
    addressAddressLine2: stripeAddress.line2 || "",
    addressCity: stripeAddress.city || "",
    addressPostcode: stripeAddress.postal_code || "",
    addressStateProvince: stripeAddress.state || "",
    addressCountryCode: countryCode,
    addressCountryName: countryName,
  }

  // set optional values only if they seem valid
  const {type = "private", name = "", attName = "", phone = "", email = ""} = optional;
  address.type = type;
  if (name) address.name = name;
  if (attName) address.attName = attName;
  if (phone) address.phoneNr = phone;
  if (email) address.email = email;

  return fromJS(address);
}

export async function handleStripeConfirmSetup(clientSecret, stripeHooks, callbacks) {
  const { stripe, elements } = stripeHooks;
  const {
    t, // "checkout" t
    setStripeErrorMessage, // In case of any errors
    addPaymentMethod, // Call to our system after retrieveing the payment method id from stripe
    onSuccess = noop,
    setSubmittingToken = noop, // optional
  } = callbacks;

  if (!stripe || !elements) {
    // Shouldn't happen: stripe isn't loaded yet.
    return;
  }

  setSubmittingToken(true);

  // Trigger form validation and wallet collection
  const {error: submitError} = await elements.submit();
  if (submitError) {
    setStripeErrorMessage(submitError.message || submitError.code);
    setSubmittingToken(false);
    return;
  }

  // Use the clientSecret and Elements instance to confirm the setup
  return stripe.confirmSetup({
    elements,
    clientSecret,
    confirmParams: {
      return_url: window.location.href,
    },
    redirect: "if_required",
  }).then((result) => {
    // If the card was successfully registered with stripe once, it will
    // error with code "setup_intent_unexpected_state" if submitted again.
    // From the anonymous checkout however, we cannot help but resubmit
    // the card details, without knowning whether the user updated them.
    const ignoreError = result.error?.code === "setup_intent_unexpected_state"

    if (result.error && !ignoreError) {
      setStripeErrorMessage(result.error.message);
    } else if (result.error) {
      // Ignore error
      setStripeErrorMessage(null);
      onSuccess();
    } else {
      // Success.
      setStripeErrorMessage(null);

      // Use result.setupIntent.payment_method from stripe to register this
      // payment method. OnSuccess is handled there
      addPaymentMethod(result.setupIntent.payment_method);
    }
  })
  .catch((_error) => {
    setStripeErrorMessage(t("common:credit_card.validating_error"));
  })
  .finally(() => {
    setSubmittingToken(false);
  });
}
import {takeLatest} from "redux-saga";
import {fork, put, take, race, call} from "redux-saga/effects";
import {generateUUID} from "../lib/UUID";
import {showModalWindow, removeModalWindow} from "../actions/WindowActions";
import Navigation from "../lib/Navigation";
import CancelCheckoutFooter from "../anonymous_checkout/components/CancelCheckoutFooter";
import {Map} from "immutable";
import i18n from '../i18n';
import {
  SHOW_ANONYMOUS_CART_NOT_FOUND_WINDOW,
  BEGIN_ANONYMOUS_CHECKOUT_SAGA,
  SELECT_ACCOUNT,
  SELECT_ACCOUNT_NO_APPLICABLE_ACCOUNTS,
  CREATE_ACCOUNT,
  POPULATE_CART,
  CHECKOUT_CART,
  ACCOUNT_READY,
  CLOSE_ERROR_WINDOW,
  REQUEST_SIGN_IN,
  REGISTER,
  REQUEST_REGISTER,
  SIGN_IN,
} from "../actions/AnonymousCheckoutActions";
import { CHECKOUT_STEPS } from "../anonymous_checkout/AnonymousCheckoutPage";
import { recordRegistrationCompleted } from "../lib/Analytics";

const headlessWindowOption = Map({headless: true, centered: true});
const headlessWindowOptionWithCancel = Map({headless: true, centered: true, footerComponent: CancelCheckoutFooter});

function* showErrorWindow(attrs = {}) {
  let errorWindowId = generateUUID();
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "anonymous-cart-not-found", cartId: action.cartId}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "checkout-failed", accountId: "a0464d70a4d64c2d8596521a6f5e2c87"}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "select-account-failed", cartId: action.cartId, countryCode: "ES"}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "account-creation-failed", cartId: action.cartId, countryCode: "ES"}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "populate-cart-failed", cartId: action.cartId, accountId: "a0464d70a4d64c2d8596521a6f5e2c87"}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "account-creation-monitor-timeout", countryCode: "ES", accountId: "a0464d70a4d64c2d8596521a6f5e2c87"}, "Checkout Failed", headlessWindowOption));
  // yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", {kind: "registration-sign-in-failed"}, "Checkout Failed", headlessWindowOption));
  yield put(showModalWindow(errorWindowId, "AnonymousCheckout.ErrorWindow", attrs, i18n.t("checkout:anonymous_checkout.failed_title"), headlessWindowOption));
  yield take(CLOSE_ERROR_WINDOW);
  yield put(removeModalWindow(errorWindowId));
}

function* doStart(action) {
  if (!action.authenticated) {
    // Don't ask to create an account nor login => redirect to anonymous checkout
    if (action.noAnonymousCheckout) {
      const windowOptions = action.fromAnonymousCheckout
        ? headlessWindowOptionWithCancel.delete("footerComponent")
        : headlessWindowOptionWithCancel;

      // Sign In.
      let signInWindowId = generateUUID();
      yield put(showModalWindow(signInWindowId, "AnonymousCheckout.SignIn", {withCancel: true, emailAddress: action.maybeEmailAddress}, "", windowOptions));
      const {onRegister, onSignedIn} = yield race({
        onRegister: take(REQUEST_REGISTER),
        onSignedIn: take(SIGN_IN.SUCCESS),
        onCancel: take(SIGN_IN.CANCEL),
      });
      yield put(removeModalWindow(signInWindowId));

      if (onSignedIn) {
        action.authenticated = true;
        yield doStart(action);
      }

      // Register.
      if (onRegister) {
        let registerWindowId = generateUUID();
        yield put(showModalWindow(registerWindowId, "AnonymousCheckout.Register", {countryCode: action.countryCode, withCancel: true}, "", windowOptions));  
        const {onSignIn, onRegistered} = yield race({
          onSignIn: take(REQUEST_SIGN_IN),
          onRegistered: take(REGISTER.SUCCESS),
          onCancel: take(REGISTER.CANCEL),
        })
        yield put(removeModalWindow(registerWindowId));

        if (onSignIn) {
          yield doStart(action); // Start again
        } else if (onRegistered) {
          // Monitor Registration.
          recordRegistrationCompleted(action.countryCode, "instashop");
          let monitorWindowId = generateUUID();
          yield put(showModalWindow(monitorWindowId, "AnonymousCheckout.MonitorRegistration", {onboardingId: onRegistered.onboardingId, email: onRegistered.email, password: onRegistered.password}, i18n.t("checkout:anonymous_checkout.setting_up_account"), headlessWindowOption));
          const {onRegisterSignInSuccess} = yield race({
            onRegisterSignInSuccess: take(SIGN_IN.SUCCESS),
            onRegisterSignInFailed: take(SIGN_IN.FAILED)
          });
          yield put(removeModalWindow(monitorWindowId));
          if (onRegisterSignInSuccess) {
            action.authenticated = true;
            yield doStart(action);
          } else {
            yield call(showErrorWindow, {kind: "registration-sign-in-failed", countryCode: action.countryCode});
            yield doStart(action); // Try again.
          }
        }
      } 
    } else {
      // Anonymous checkout
      Navigation.gotoAnonymousCheckout(action.cartId, CHECKOUT_STEPS.STEP_INFORMATION, "", "instashop");
    }
  } else {
    // SIGNED IN
    let useAccountId = "";

    // Select Account.
    let selectAccountWindowId = generateUUID();
    yield put(showModalWindow(selectAccountWindowId, "AnonymousCheckout.SelectAccount", {cartId: action.cartId}, i18n.t("checkout:anonymous_checkout.select_account.continue_title"), headlessWindowOptionWithCancel));
    const {onSelectAccountSuccess, onSelectAccountFailed, onNoApplicableAccounts} = yield race({
      onSelectAccountSuccess: take(SELECT_ACCOUNT.SUCCESS),
      onSelectAccountFailed: take(SELECT_ACCOUNT.FAILED),
      onNoApplicableAccounts: take(SELECT_ACCOUNT_NO_APPLICABLE_ACCOUNTS)
    })
    yield put(removeModalWindow(selectAccountWindowId));
    if (onSelectAccountSuccess) {
      useAccountId = onSelectAccountSuccess.accountId;
    }

    // Create Account for country.
    if (onNoApplicableAccounts) {
      let createAccountWindowId = generateUUID();
      yield put(showModalWindow(createAccountWindowId, "AnonymousCheckout.CreateAccount", {countryCode: action.countryCode}, i18n.t("checkout:anonymous_checkout.creating_account.title"), headlessWindowOption));
      const {onAccountCreated, onAccountCreationFailed} = yield race({
        onAccountCreated: take(CREATE_ACCOUNT.SUCCESS),
        onAccountCreationFailed: take(CREATE_ACCOUNT.FAILED)
      })
      yield put(removeModalWindow(createAccountWindowId));
      if (onAccountCreationFailed) {
        yield call(showErrorWindow, {kind: "account-creation-failed", cartId: action.cartId, countryCode: action.countryCode});
        return;
      }

      // Monitor Account Cart Status
      if (onAccountCreated) {
        useAccountId = onAccountCreated.accountId;
        let accountCreationMonitorWindowId = generateUUID();
        yield put(showModalWindow(accountCreationMonitorWindowId, "AnonymousCheckout.MonitorAccountCreation", {accountId: useAccountId}, i18n.t("checkout:anonymous_checkout.monitoring_title"), headlessWindowOption));
        const {onAccountCreationMonitorFailed} = yield race({
          onAccountCreationMonitorSuccess: take(ACCOUNT_READY.SUCCESS),
          onAccountCreationMonitorFailed: take(ACCOUNT_READY.FAILED)
        })
        yield put(removeModalWindow(accountCreationMonitorWindowId));
        if (onAccountCreationMonitorFailed) {
          yield call(showErrorWindow, {kind: "account-creation-monitor-timeout", cartId: action.cartId, accountId: useAccountId, countryCode: action.countryCode});
          return;
        }        
      }      
    }
    
    // Problem loading the accounts.
    if (onSelectAccountFailed) {
      yield call(showErrorWindow, {kind: "select-account-failed", cartId: action.cartId, countryCode: action.countryCode});
      yield doStart(action); // Try again.
      return;      
    }    

    // Populate Cart.
    let populateCartWindowId = generateUUID();
    yield put(showModalWindow(populateCartWindowId, "AnonymousCheckout.PopulateCart", {cartId: action.cartId, accountId: useAccountId }, i18n.t("checkout:anonymous_checkout.preparing_title"), headlessWindowOption));
    const {onPopulateCartFailed} = yield race({
      onPopulateCartSuccess: take(POPULATE_CART.SUCCESS),
      onPopulateCartFailed: take(POPULATE_CART.FAILED)
    })
    yield put(removeModalWindow(populateCartWindowId));
    if (onPopulateCartFailed) {
      yield call(showErrorWindow, {kind: "populate-cart-failed", cartId: action.cartId, accountId: useAccountId, countryCode: action.countryCode});
      return;      
    }

    // Checkout
    let checkoutCartWindowId = generateUUID();
    yield put(showModalWindow(checkoutCartWindowId, "AnonymousCheckout.Checkout", {accountId: useAccountId, cartId: action.cartId }, i18n.t("checkout:anonymous_checkout.preparing_title"), headlessWindowOption));
    const {onCheckoutSuccess, onCheckoutFailed} = yield race({
      onCheckoutSuccess: take(CHECKOUT_CART.SUCCESS),
      onCheckoutFailed: take(CHECKOUT_CART.FAILED)
    });
    yield put(removeModalWindow(checkoutCartWindowId));
    if (onCheckoutSuccess) {
      // TODO: We could clear the website cart by loading it in an iframe.
      Navigation.gotoCheckout(useAccountId, onCheckoutSuccess.orderId, "instashop");
    } else if (onCheckoutFailed) {
      yield call(showErrorWindow, {kind: "checkout-failed", accountId: useAccountId, countryCode: action.countryCode});
    }
  }
}

function* showCartNotFound(action) {
  yield call(showErrorWindow, {kind: "anonymous-cart-not-found", cartId: action.cartId}); 
}

function* watchCartNotFound() {
  yield takeLatest(SHOW_ANONYMOUS_CART_NOT_FOUND_WINDOW, showCartNotFound);
}

export default function* AnonymousCheckoutSaga() {
  yield fork(watchCartNotFound);
  yield takeLatest(BEGIN_ANONYMOUS_CHECKOUT_SAGA, doStart);
}
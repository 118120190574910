import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../AnonymousCheckoutPage.module.scss";
import AddressInline from "../../../shared/AddressInline";
import Button from "../../../shared/components/Button";
import { getCountryName } from "../../../lib/CountriesAndStates";
import { INFORMATION_ANCHORS } from "../stepInformation/StepInformation";


export default function InformationStepSummary(props) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_payment.information_step_summary"});

  const {
    email,
    shippingAddress,
    gotoPreviousStep,
  } = props;

  if (!email || !shippingAddress) return null;

  const countryName = getCountryName(shippingAddress.get("addressCountryCode"));
  const addressWithCountryName = shippingAddress.get("addressCountryName")
    ? shippingAddress
    : shippingAddress.set("addressCountryName", countryName);
  
  return (
    <div className={styles.informationStepSummary}>
      <Item
        key="email"
        title={t("email")}
        text={email}
        actionLabel={t("changeEmail")}
        onAction={() => gotoPreviousStep(INFORMATION_ANCHORS.ACCOUNT)}>
        {email}
      </Item>
      <Item
        key="shipping_address"
        title={t("shipping_address")}
        actionLabel={t("change_shipping_address")}
        onAction={() => gotoPreviousStep(INFORMATION_ANCHORS.SHIPPING_ADDRESS)}>
        <AddressInline inline={true} showType={false} address={addressWithCountryName} />
      </Item>
    </div>
  )
}


function Item({title, children, actionLabel, onAction}) {
  return (
    <div className={styles.item}>
      <div className={styles.info}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{children}</div>
      </div>
      <div className={styles.actions}>
        <Button link onClick={onAction}
          label={actionLabel}
        />
      </div>
    </div>
  );
}
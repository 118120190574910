import React from "react";
import useQueryMap from "../../hooks/useQueryMap";
import { useTranslation } from "react-i18next";
import BasketWithTag from "../../shop/shared/BasketWithTag";
import Conditional from "../../shared/Conditional";
import { buildWebsiteLink } from "../../lib/Navigation";
import styles from "../AnonymousCheckoutPage.module.scss";
import { Map } from "immutable";


export default function FakeMobileHeader(props) {
  const {
    gotoCart,
    products = Map(),
    show = false,
  } = props;

  const query = useQueryMap();
  const { t } = useTranslation("links");

  const count = products.reduce((acc, p) => acc + p.get("quantity", 1), 0);

  return (
    <Conditional show={show}>
      <div className={styles.fakeMobileHeader}>
        <a className={styles.logo} href={buildWebsiteLink(query)}
          aria-label={t("goto_home")}>
          <img
            src="/assets/LogoSimple-Black.svg"
            alt={t("hallstein:artesian_water")}
            width={89}
            height={37}
          />
        </a>
        <BasketWithTag side="32px" onClick={gotoCart} count={count} />
      </div>
    </Conditional>
  )
}
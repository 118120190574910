import React from "react";
import {Field, getFormValues, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import styles from "./PromoCodeForm.module.scss";


function upcase(val) {
  return String(val).toUpperCase();
}

function PromoCodeForm(props) {
  const { t } = useTranslation("checkout");
  const {
    noLabel,
    errors, handleSubmit, submit, submitting,
    inline = false,
    small = false,
  } = props;
  
  let submitLabel = submitting ? t("common:standby") : t("summary.promo_code.apply");

  const formValues = useSelector(state => getFormValues("PromoCodeForm")(state));
  const codeFormHasValue = !!formValues?.get("promoCode");

  const baseClasses = [
    styles.module,
    inline ? styles.inline : "",
    small ? styles.small : "",
  ].join(" ")

  return (
    <form className={baseClasses} onSubmit={handleSubmit}>
      <div className={styles.inner}>
        <Field
          name="promoCode"
          label={noLabel ? "" : t("summary.promo_code.label")}
          placeholder={t("summary.promo_code.placeholder")}
          component={TextInputField}
          normalize={upcase}
          mode=""
          table={false}
          serverError={errors.promoCode}
        />
        <Button label={submitLabel} className={styles.submitButton}
          onClick={submit} active={!submitting && codeFormHasValue}
          size={small ? Button.SIZES.SMALL : Button.SIZES.MEDIUM} />
      </div>
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "PromoCodeForm"
})(PromoCodeForm);

export default ReduxForm;
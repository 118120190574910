import React, {useState} from "react";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import {requestSignIn, registered, registerCancelled} from "../../actions/AnonymousCheckoutActions";
import Form from "../../onboarding/forms/RegistrationForm";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validatePresence, validateAcceptance, validatePhoneNumber, validatePassword} from "../../lib/Validators";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {Map} from "immutable";
import useQueryMap from "../../hooks/useQueryMap";
import { useTranslation } from "react-i18next";
import { getReferralCodeFromUrl } from "../../onboarding/views/Registration";


function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "email");
  errors = validatePassword(errors, values, "password");
  errors = validatePresence(errors, values, "phone");
  errors = validatePhoneNumber(errors, values, "phone");
  errors = validateAcceptance(errors, values, "tacAccepted", "yes");

  return errors.toJS();
}

const RegisterFormContainer = (props) => {
  const {countryCode, requestSignIn, registered, startOnboarding} = props;
  const { t, i18n } = useTranslation("checkout", { keyPrefix: "anonymous_checkout.create_account" });
  const [errors, setErrors] = useState([]);
  const query = useQueryMap();

  function handleSignIn() {
    requestSignIn();
  }

  function handleSubmit(values) {
    let updatedValues = Map({
      name: values.get("name"),
      email: values.get("email"),
      phone: getCompletePhoneNrOrNothing(values.get("phone")),
      password: values.get("password"),
      locale: i18n.language,
      tacAccepted: values.get("tacAccepted") === "yes",
      marketingAccepted: values.get("marketingAccepted") === "yes",
      countryCode: countryCode,
      referralCode: getReferralCodeFromUrl(query), // Can only come from query (field isn't used),
      gclid: query.get("gclid", ""),
      fbclid: query.get("fbclid", "")
    });
    return startOnboarding(updatedValues.toJS())
      .then((response) => {
        if (response.data.startOnboarding.errors.length <= 0) {
          registered(response.data.startOnboarding.onboardingId, updatedValues.get("email"), updatedValues.get("password"));
        } else {
          let errors = createValidationErrors(response.data.startOnboarding.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });      
  }

  return (
    <div>
      <div>
        <p className="sg-text-large u-centered sg-mb-tiny sg-primary-blue-700">{t("title")}</p>
        <p className="u-centered">{t("text")}</p>
      </div>
      <Form
        errors={errors}
        validate={validateForm}
        onSignIn={handleSignIn}
        onSubmit={handleSubmit}
        darkBackground={false}
        countryCode={countryCode}
        showReferralCode={false}
        withCancel={props.withCancel}
        onCancel={props.registerCancelled}
        />
    </div>
  )
}

const START_ONBOARDING = gql`
  mutation startOnboarding($data: StartOnboardingInput!) {
    startOnboarding(input: $data) {
      onboardingId
      errors { key message }
    }
  }
`;


const withMutation = compose(
  graphql(START_ONBOARDING, {
    props: ({ mutate }) => ({
      startOnboarding: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);


export default connect(null, {showSupport, requestSignIn, registered, registerCancelled})(withMutation(RegisterFormContainer));

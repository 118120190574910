import React, {useState} from "react";
import {connect} from "react-redux";
import SignIn from "../../login/views/SignIn";
import {requestRegister, signedIn, signInCancelled} from "../../actions/AnonymousCheckoutActions";
import {validateEmail, validatePassword} from "../../lib/Validators";
import Auth from "../../Auth";
import {Map} from "immutable";
import { useTranslation } from "react-i18next";


const SignInFormContainer = (props) => {
  const { t } = useTranslation("checkout", { keyPrefix: "anonymous_checkout" });
  const [errors, setErrors] = useState([])

  function handleSignUp(e) {
    e.preventDefault();
    props.requestRegister();    
  }

  const initialValues = {}

  let title = undefined; // Default in SignIn component
  let subtitle = t("sign_in_text");
  let showNoAccountOption = true;

  if (props.emailAddress) {
    // We know the email address when a user tries to create an anonymous
    // account and we show this SignIn form.
    title = t("existing_address_title");
    subtitle = t("existing_address_text");
    showNoAccountOption = false; // In this mode we don't want the user to create an account

    initialValues.username = props.emailAddress;
  }

  return (
    <>
      <SignIn
        title={title}
        subtitle={subtitle}
        onSubmit={(values) => handleSubmit(values, setErrors, props.cartId, props.signedIn)}
        onSignUp={handleSignUp}
        darkBackground={false}
        errors={errors}
        initialValues={initialValues}
        validate={(values) => validateForm(values, setErrors)}
        withCancel={props.withCancel}
        emailAddress={props.emailAddress}
        onCancel={props.signInCancelled}
        showNoAccountOption={showNoAccountOption}
      />
    </>
  )
}

function handleSubmit(values, setErrors, cartId, signedInFn) {
  const data = values.toJS();
  const onError = (descr) => setErrors({"loginForm": descr});
  const onSuccess = (params) => signedInFn(params);
  new Auth().loginWithCredentials(data.username, data.password, {cartId: cartId}, {onError: onError, onSuccess: onSuccess});
}

function validateForm(values, setErrors) {
  setErrors([]); // Reset error state as we want to stop showing the "Wrong
  // username or password" message when the user modifies the fields.
  
  let errors = Map();
  errors = validateEmail(errors, values, "username");
  errors = validatePassword(errors, values, "password");
  return errors.toJS();
}

export default connect(null, {requestRegister, signedIn, signInCancelled})(SignInFormContainer);

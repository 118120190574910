import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Form from "../../../shop/forms/NewPaymentElementForm";
import {Map} from "immutable";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import styles from "../../../shop/forms/SetupCreditCardFormContainer.module.scss";
import { StripeElements } from "../../../shop/views/Checkout/StripeElements";


function validateForm(_values) {
  let errors = Map();
  return errors.toJS();
}

// Used when an account doesn't have a credit card set up yet and is not
// displayed inside a popin, so the user cannot "cancel" this form.
function SetupCreditCardFormContainer(props) {
  const {
    anonymousCartId,
    shopId,
    shopName,
    clientSecret, // Mandatory, so we can reuse the same for all checkout payment forms
    onCardAdded = noop,
    onError = noop,
    status,
  } = props;
  const { t } = useTranslation("common");

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [_added, setAdded] = useState(false);

  function handleSubmit(paymentMethodId) {
    let values = {anonymousCartId: anonymousCartId, paymentMethodId: paymentMethodId};
    setSubmitting(true);

    props.anonymousCheckoutAddPaymentMethod(values)
      .then((response) => {
        if (response.data.anonymousCheckoutAddPaymentMethod.errors.length <= 0) {
          setAdded(true);
          onCardAdded();
        } else {
          let better_errors = {_error: t("credit_card.add_failure")}
          setErrors(better_errors);
        }
      })
      .catch((_err) => {
        let better_errors = {_error: t("credit_card.add_failure")}
        setErrors(better_errors);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  useEffect(() => {
    if (errors && errors != []) {
      onError(errors);
    }
  }, [errors]);

  return (
    <StripeElements useLoadingLine={true}
      shopId={shopId}
      clientSecret={clientSecret}>
      <Form
        clientSecret={clientSecret}
        shopName={shopName}
        submitting={submitting}
        submitErrors={errors}
        validate={validateForm}
        onSubmit={handleSubmit}
        onError={onError}
        onSuccess={onCardAdded}
        showSubmitButton={false}
        textClass="sg-text-small" // Prevent default
        buttonsClass={styles.buttons} // Custom css for this case
        status={status}
        />
    </StripeElements>
  );
}

const ADD_PAYMENT_METHOD = gql`
  mutation anonymousCheckoutAddPaymentMethod($data: PaymentMethodIdInput!) {
    anonymousCheckoutAddPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(ADD_PAYMENT_METHOD, {
    props: ({ mutate }) => ({
      anonymousCheckoutAddPaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default withQueries(SetupCreditCardFormContainer);


import React from "react";
import { RichText } from 'prismic-reactjs';
import { findSurchageForCountry, getPrismicProductId, getPrismicProductSku, hasRelatedProducts, isProduct, isSimpleCard } from "../../lib/ProductsInformation";
import styles from "./ProductListing.module.scss";
import InstashopProduct from "./InstashopProduct";
import SimpleCard, { simpleCardConfigFromPrismic } from "./SimpleCard";
import { useTranslation } from "react-i18next";
import { fromJS } from "immutable";
import ProductRecordView from "./ProductRecordView";

const ProductListing = (props) => {
  const {
    prismicProducts, // From... prismic!
    productsPrices, // From public API
    country, // Valid country
    // Display as main product + related products if prismicProducts has one element:
    highlightIfAlone,
  } = props;

  if (!prismicProducts || !productsPrices || !country) {
    return null;
  }
  
  if (prismicProducts.size === 1 && highlightIfAlone) {
    const product = prismicProducts.first().get("product");
    const productUID = getPrismicProductId(product);
    const pricesData = extractPriceInfo(productsPrices, getPrismicProductSku(product), country);

    const relativeProducts = hasRelatedProducts(product) && (
      <ProductListing
        prismicProducts={product.get("related_products")}
        productsPrices={productsPrices}
        country={country}
        gotoProduct={props.gotoProduct}
        viewAllProductsLink={props.viewAllProductsLink}
        highlightIfAlone={false}
        cartAddProduct={props.cartAddProduct}
        cartAddSubscription={props.cartAddSubscription}
      />
    );

    const parentClass = relativeProducts && styles.withRelativeProducts;

    return (
      <div className={parentClass}>
        <div className={styles.mainProduct}>
          <ProductRecordView product={product} shopId={productsPrices.shop} prices={fromJS(pricesData)} />
          <Product key={productUID} uid={productUID} highlighted={true}
            shopId={productsPrices.shop}
            {...product.toJS()} {...pricesData}
            gotoProduct={props.gotoProduct}
            viewAllProductsLink={props.viewAllProductsLink}
            cartAddProduct={props.cartAddProduct}
            cartAddSubscription={props.cartAddSubscription}
          />
        </div>
        { relativeProducts }
      </div>
    );
  }
  else {
    // Build list of products/simpleCards in the prismic order
    // NB: if there are no valid product (regardless of cards), we return null.
    let hasValidProduct = false;

    const productsElems = prismicProducts.map((wrap) => {
      const productOrCard = wrap.get("product");
      const productUID = getPrismicProductId(productOrCard);
      if (isSimpleCard(productOrCard)) {
        return (
          <SimpleCard key={productUID} {...simpleCardConfigFromPrismic(productOrCard)}
            className={styles.relatedProductsCard}
          />
        );
      }
      else if (isProduct(productOrCard)) {
        const pricesData = extractPriceInfo(productsPrices, getPrismicProductSku(productOrCard), country);
        
        if (checkValidity(productOrCard, pricesData, country)) {
          hasValidProduct = true;
          return (
            <Product key={productUID} uid={productUID}
              shopId={productsPrices.shop}
              {...productOrCard.toJS()} {...pricesData}
              gotoProduct={props.gotoProduct}
              cartAddProduct={props.cartAddProduct}
              cartAddSubscription={props.cartAddSubscription}
            />
          );
        }
        else {
          return null; // prismic product defined but no price for this country
        }
      }

      return null;
    });

    return (
      <div className={styles.products}>
        { hasValidProduct && productsElems }
      </div>
    )
  }
}

// We get the display data from prismic, and the prices from the public API.
// This function helps extract the necessary props to be used in the Products.
export const extractPriceInfo = (productsPrices, sku, country) => {
  const oneTimeInfo = productsPrices.products.find((p) => p.sku === sku);
  const subscriptionInfo = productsPrices.subscriptions.find((p) => p.sku === sku);

  if (!oneTimeInfo && !subscriptionInfo) {
    return null;
  }
  
  const surchargeAmount = findSurchageForCountry(productsPrices, country, sku);

  const pricesData = {
    price: oneTimeInfo && oneTimeInfo.unit_price,
    subscriptionPrice: subscriptionInfo && subscriptionInfo.unit_price,
    currency: productsPrices.currency,
    surcharge: surchargeAmount,
  }

  return pricesData;
}

const checkValidity = (prismicProductData, pricesData, country) => {
  if (!prismicProductData || !pricesData) {
    return false;
  }

  return true;
}

const Product = (props) => {
  const { highlighted } = props;
  const { t } = useTranslation("products");

  // Make sure to never render products that have no price defined
  if (!props.price && !props.subscriptionPrice) {
    return null;
  }

  // TODO?
  // const active = canOrderProduct(cart, x.get("productSku"), x.get("kind"));
  // Also: isInCart(products, productSku, kind)

  const prismicImages = highlighted ? props.images : props.listing_image;
  const longTitle = props.long_title || props.title;
  const title = props.title;
  const subtitle = null; // Used only in pricing page
  const description = highlighted ? props.long_description : props.description;
  const descriptionElem = <RichText render={description} />;

  const additionnalInfo = highlighted && (
    <div className={styles.additionnalInfo}>
      <div className={styles.legalese}>
        {t("tax_not_included")}<br/><br/>
        {t("tax_on_water_included_for_austrians")}<br/><br/>
        {t("surcharge_may_apply")}
      </div>
      { props.viewAllProductsLink }
    </div>
  );

  const onProductClick = (sku, title, kind, status, numberProducts) => {
    props.cartAddProduct(sku, title, "product", status, numberProducts, props.price, props.currency);
  }
  const onSubscriptionClick = (sku, title, kind, status, numberProducts) => {
    props.cartAddSubscription(sku, title, "subscription", status, numberProducts, props.price, props.currency);
  }

  const tagsClasses = props.tags && props.tags.flatMap((t) => t.tag + "Tag");
  const productClasses = [
    props.className,
    tagsClasses.join(" "),
  ];

  return (
    <InstashopProduct
      { ...props }
      longTitle={longTitle}
      title={title}
      onClick={() => props.gotoProduct(props.uid)}
      subtitle={subtitle}
      prismicImages={prismicImages}
      subscriptionTitle={t("subscribe_and_save")}
      additionnalInfo={additionnalInfo}
      className={productClasses.join(" ")}
      onProductClick={onProductClick}
      onSubscriptionClick={onSubscriptionClick}
      >
      { descriptionElem }
    </InstashopProduct>
  );
}

export default ProductListing;

import React from "react";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import SetupCreditCardFormContainer from "./SetupCreditCardFormContainer";
import { Map } from "immutable";
import CreditCard from "../../../shared/CreditCard";


export function PaymentMethod(props) {
  const {
    anonymousCheckoutInfo = Map(),
    stripeClientSecret,
    status,
    onStripeSuccess,
    onStripeError,
  } = props;

  const isReady = !!anonymousCheckoutInfo.get("accountId");
  if (!isReady) {
    return <LoadingLine />
  }

  const pm = anonymousCheckoutInfo.get("billingPaymentMethod");
  if (pm && pm.get("paymentMethodId")) {
    // Fast checkout PM was setup
    return (
      <CreditCard card={pm} />
    )
  }

  // No fast checkout PM was setup so we display the strip form
  return (
    <SetupCreditCardFormContainer
      anonymousCartId={anonymousCheckoutInfo.get("cartId")}
      shopId={anonymousCheckoutInfo.get("shopId")}
      shopName={anonymousCheckoutInfo.get("shopName")}
      clientSecret={stripeClientSecret}
      status={status}
      onCardAdded={onStripeSuccess}
      onError={onStripeError}>
    </SetupCreditCardFormContainer>
  );
}


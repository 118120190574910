import MessageBus from "./MessageBus";
import { WEBSITE_ROOT } from "../config";
import { patchSearchParams } from "./PermanentUrlParams";
import i18n from "../i18n";
import { DEFAULT_LOCALE } from "./Locales";

// goto uses the current locale by default but can be given another one
function goto(url, state = {}, desiredLocale = null) {
  publishNavigation("goto", url, state, desiredLocale);
}

// replacePath uses the current locale by default but can be given another one
function replacePath(url, state = {}, desiredLocale = null) {
  publishNavigation("replaceHistory", url, state, desiredLocale);
}

// Helper
function publishNavigation(type, url, state, desiredLocale) {
  const locale = desiredLocale || i18n.language || DEFAULT_LOCALE;

  // NB: i18next uses the url to get the current locale, BUT this works only
  // when really navigating and not simply patching history. So, we need to
  // update the current locale if necessary.
  if (locale !== i18n.language) {
    i18n.changeLanguage(locale);
  }

  MessageBus.publish("navigation", type, {
    url: `/${locale}${url}`,
    state: state,
  });
}


export function getShopOriginFromLocationState(locationState, defaultOrigin = "shop") {
  if (locationState && locationState.shopOrigin && locationState.shopOrigin !== "") {  
    return locationState.shopOrigin;
  }
  return defaultOrigin;
}

export function buildWebsiteLink(query, relativeUrl = "", noLocale = false) {
  const baseUrl = WEBSITE_ROOT || "https://www.hallsteinwater.com";
  const relativeUrlPrefix = noLocale ? "" : `/${i18n.language}`;
  const url = `${baseUrl}${relativeUrlPrefix}${relativeUrl}`;
  return query ? patchSearchParams(query, url) : url;
}
export function buildProductImageLink(productSku) {
  return buildWebsiteLink(null, `/images/emails/products/${productSku}.jpg`, true)
}

const Navigation = {
  replaceHistory: (path, state = {}, desiredLocale = null) => {
    replacePath(path, state, desiredLocale);
  },

  gotoTermsAndConditions: () => {
    // Forget the tracking info for that one..
    window.open(buildWebsiteLink(null, "/terms-and-conditions"), "_blank");
  },

  goBack: () => {
    // Go back in history.
    MessageBus.publish("navigation", "goBack", {});
  },
  
  signUp: () => {
    goto("/signup");
  },

  gotoRoot: () => {
    goto("/");
  },

  goto: (path, state = {}, desiredLocale = null) => {
    goto(path, state, desiredLocale);
  },

  signIn: () => {
    goto("/signin");
    // MessageBus.publish("auth", "login", {});
  },

  signOut: () => {
    MessageBus.publish("auth", "logout", {});
  },

  gotoOrderSuccess: (accountId, orderId) => {
    goto(`/client-section/accounts/${accountId}`);
  },

  gotoSignupView: (view, params = "") => {
    goto(`/signup?view=${view}&${params}`);
  },

  gotoAccount: (accountId) => {
    goto(`/client-section/accounts/${accountId}`);
  },
  gotoAccountSubscription: (accountId, subscriptionId) => {
    goto(`/client-section/accounts/${accountId}?#${subscriptionId}`);
  },
  gotoAccountReplaceHistory: (accountId, desiredLocale) => {
    replacePath(`/client-section/accounts/${accountId}`, {}, desiredLocale);
  },

  gotoSelectAccount: () => {
    goto("/client-section/accounts")
  },

  gotoCheckout: (accountId, orderId, shopOrigin="shop") => {
    goto(`/client-section/accounts/${accountId}/checkout/${orderId}`, {shopOrigin: shopOrigin});
  },

  gotoAnonymousCheckout: (anonymousCartId, step, anchor = "", shopOrigin="shop") => {
    goto(`/checkout/${anonymousCartId}/new/${step}${anchor ? "#" + anchor : ""}`, {shopOrigin: shopOrigin});
  },

  gotoCheckoutReplaceHistory: (accountId, orderId, shopOrigin="shop") => {
    replacePath(`/client-section/accounts/${accountId}/checkout/${orderId}`, {shopOrigin: shopOrigin});
  },

  gotoShop: (accountId) => {
    goto(`/client-section/accounts/${accountId}/shop`);
  },
  gotoShopReplaceHistory: (accountId, desiredLocale) => {
    replacePath(`/client-section/accounts/${accountId}/shop`, {}, desiredLocale);
  },
  gotoShopProduct: (accountId, productId) => {
    goto(`/client-section/accounts/${accountId}/shop/product/${productId}`);
  },
  gotoShopProductReplaceHistory: (accountId, productId, desiredLocale) => {
    replacePath(`/client-section/accounts/${accountId}/shop/product/${productId}`, {}, desiredLocale);
  },
  gotoShopForTag: (accountId, tagName) => {
    goto(`/client-section/accounts/${accountId}/shop/products/byTag/${tagName}`);
  },

  gotoCart: (accountId) => {
    goto(`/client-section/accounts/${accountId}/cart`);
  },

  isCartPage: (pathname) => {
    return String(pathname).indexOf("cart") > -1;
  },

  gotoDispenserGuide: (accountId) => {
    goto(`/client-section/accounts/${accountId}/shop/dispenser`);
  },

  gotoSettings: (accountId) => {
    goto(`/client-section/accounts/${accountId}/settings`);
  },
  gotoSettingsReplaceHistory: (accountId, desiredLocale) => {
    replacePath(`/client-section/accounts/${accountId}/settings`, {}, desiredLocale);
  },

}

export default Navigation

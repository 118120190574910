import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {accountCreated, accountCreationFailed} from "../../actions/AnonymousCheckoutActions";
import {createErrors} from "../../lib/ErrorFormatter";
import SmallMessage from "../../shop/shared/SmallMessage";
import {getCountryName} from "../../lib/CountriesAndStates";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { useTranslation } from 'react-i18next';


const CreateAccountFormContainer = ({countryCode, customerAddAccount, accountCreated, accountCreationFailed}) => {
  const { t } = useTranslation("checkout", { keyPrefix: "anonymous_checkout.creating_account" });
  const [status, setStatus] = useState("idle");
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    function addAccount(countryCode) {
      customerAddAccount({name: t("default_account_name"), countryCode: countryCode})
      .then((response) => {
        if (response.data.customerAddAccount.errors.length <= 0) {
          accountCreated(response.data.customerAddAccount.accountId);
        } else {
          setStatus("failed");
          accountCreationFailed();
        }
      })
      .catch((err) => {
        setErrorMsg(createErrors(err)._error);
        setStatus("failed");
        accountCreationFailed();
      })
    }

    if (status === "idle") {
      setStatus("creating-account");
      addAccount(countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, status]);

  return (
    <SmallMessage title={t("title")}
      icon={<LoadingCircle />}
      message={t("text", { country: getCountryName(countryCode) })} />
  );
}

const ADD_ACCOUNT = gql`
  mutation customerAddAccount($data: CustomerAddAccountInput!) {
    customerAddAccount(input: $data) {
      accountId
      errors { key message }
    }
  }
`;

const withMutation = compose(
  graphql(ADD_ACCOUNT, {
    props: ({ mutate }) => ({
      customerAddAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  }) 
);

export default connect(null, {accountCreated, accountCreationFailed})(withMutation(CreateAccountFormContainer));
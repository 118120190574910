import React, {Component} from "react";
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import {reportShowOrderStatusSuccess, cancelShowOrderStatus} from "../../actions/ShopActions";
import {formatInvoiceNr} from "../../lib/TextFormatter";
import AuthenticatePayment from "./AuthenticatePayment";
import SmallMessage from "../shared/SmallMessage";
import styles from "./PlaceOrderFormContainer.module.scss";
import SmallErrorMessage from "../shared/SmallErrorMessage";
import { withTranslation } from "react-i18next";
import WaterDisc from "../../shared/rive/WaterDisc";


class PlaceOrderFormContainer extends Component {

  render() {
    return (
      <div className={styles.module}>
        {this.renderView(this.props.status)}
      </div>
    );
  }

  renderView(status) {
    const waitinAnimation = <WaterDisc />

    switch(status) {
      case "success":
        return (
          <SmallMessage title={this.props.t("place_order.success.title")} titleAsGuideHeader={true}
            className={styles.darkBackground}
            icon={<div className={styles.logoMark} />}
            message={this.props.t("place_order.success.message", {invoice_number: formatInvoiceNr(this.props.invoiceNr)})}
            button={this.props.button || <Button label={this.props.t("place_order.success.ok_label")}
              onClick={this.handleContinue.bind(this)}
              theme={Button.THEMES.WHITE}
            />}
          />
        );
      case "error":
        return (
          <SmallErrorMessage titleAsGuideHeader={true}
            message={formatErrorMessage(this.props.errorMsg, this.props.t)}
            button={this.props.button || <Button label={this.props.t("common:close")} onClick={this.handleCancel.bind(this)} />}
          />
        );
      case "authenticate":
        return (
          <SmallMessage title={this.props.t("common:standby")} titleAsGuideHeader={true}
            icon={waitinAnimation}
            message={this.props.t("place_order.authenticate.message")}
            button={this.props.button || <Button label={this.props.t("common:close")} onClick={this.handleCancel.bind(this)} />}>
            <AuthenticatePayment 
              accountId={this.props.accountId}
              orderId={this.props.orderId} 
            />
          </SmallMessage>
        );
      case "verifying":
        return (
          <SmallMessage title={this.props.t("common:standby")} titleAsGuideHeader={true}
            icon={waitinAnimation}
            message={this.props.t("place_order.verifying.message")}>
            <AuthenticatePayment 
              accountId={this.props.accountId}
              orderId={this.props.orderId} 
            />
          </SmallMessage>
        );
      default:
        return (
          <SmallMessage title={this.props.t("common:standby")} titleAsGuideHeader={true}
            icon={waitinAnimation}
            message={this.props.t("place_order.default.message")}
          />
        );
    }
  }

  handleContinue() {
    this.props.reportShowOrderStatusSuccess();
  }

  handleCancel() {
    this.props.cancelShowOrderStatus();
  }

}

function formatErrorMessage(msg, t) {
  switch(msg) {
    case "account_delinquent":
      return t("place_order.errors.account_delinquent")
    
    default:
      return t(msg);
  }
}

export default withTranslation("checkout")(connect(null, {
  reportShowOrderStatusSuccess,
  cancelShowOrderStatus
})(PlaceOrderFormContainer));
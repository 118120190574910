import React, {Component} from "react";
import Form from "./PromoCodeForm";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import {reset} from "redux-form/immutable"
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { withTranslation } from 'react-i18next';


class PromoCodeFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = values.set("orderId", this.props.orderId)
                              .set("accountId", this.props.accountId);
    // Don't do anything when we don't have a code.
    // This way we don't show error message needlessly.
    if (values.get("promoCode", "") === "") {
      this.setState({errors: {
        promoCode: this.props.t("summary.promo_code.error_empty"),
      }});
      
      return;
    }

    return this.props.customerApplyPromoCodeToCheckout(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerApplyPromoCodeToCheckout.errors.length <= 0) {
          this.props.dispatch(reset("PromoCodeForm"));
          this.setState({errors: []});
        } else {
          let errors = createValidationErrors(response.data.customerApplyPromoCodeToCheckout.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  render() {
    return (
      <Form
        errors={this.state.errors}
        onSubmit={this.handleSubmit.bind(this)}
        small={this.props.small}
        />
    )
  }

}

const APPLY_CHECKOUT_DISCOUNT = gql`
  mutation customerApplyPromoCodeToCheckout($data: CustomerPromoCodeInput!) {
    customerApplyPromoCodeToCheckout(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(APPLY_CHECKOUT_DISCOUNT, {
    props: ({ mutate }) => ({
      customerApplyPromoCodeToCheckout: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);


export default withTranslation("checkout")(connect()(withQueries(PromoCodeFormContainer)));
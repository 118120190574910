import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {
  cancelChangePaymentTermsToCreditCard,
  requestAddCreditCard
} from "../../actions/AccountActions";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import Button from "../../shared/components/Button";
import { useTranslation } from "react-i18next";
import { ALL_CARD_TYPES } from "../../lib/Money";



function ChangePaymentTermsToCreditCardFormContainer(props) {
  const { data } = props;

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />    

  const hasActiveSubs = data.customerSubscriptions.some((s) => s.status === "active");
  const hasDefaultCard = data.customerDefaultPaymentMethod && ALL_CARD_TYPES.includes(data.customerDefaultPaymentMethod.type);
  const needsToAddCreditCard = hasActiveSubs && !hasDefaultCard;

  return needsToAddCreditCard ? <AddCreditCardPanel {...props} /> : <SwitchToCreditCardPanel {...props} />
}


function AddCreditCardPanel(props) {
  const { t } = useTranslation("common");
  
  const addCreditCard = () => {
    // Close current popup and show the add card popup
    props.cancelChangePaymentTermsToCreditCard();
    props.requestAddCreditCard(props.accountId);
  }

  // The form is there to reuse the form css for buttons
  return (
    <form>
      <p>{t("credit_card.add_cc_to_exit_payment_terms")}</p>
      <div class="buttons">
        <Button label={t("credit_card.add")} theme={Button.THEMES.BLUE} onClick={addCreditCard} />
        <Button label={t("cancel")} theme={Button.THEMES.BLUE_OUTLINE} onClick={props.cancelChangePaymentTermsToCreditCard} />
      </div>
    </form>
  );
}

function SwitchToCreditCardPanel(props) {
  const { t } = useTranslation("common");

  function handleConfirm() {
    props.customerChangePaymentMethodToCreditCard(props.accountId)
    .then((response) => {
      props.cancelChangePaymentTermsToCreditCard();
    })
    .catch(err => {
      console.log("Change payment to CC", err);
      alert("An error occured, please try again.");
      props.cancelChangePaymentTermsToCreditCard();
    });
  }

  // The form is there to reuse the form css for buttons
  return (
    <form>
      <p>{t("credit_card.confirm_exit_payment_terms")}</p>
      <div class="buttons">
        <Button label={t("confirm")} theme={Button.THEMES.BLUE} onClick={handleConfirm} />
        <Button label={t("cancel")} theme={Button.THEMES.BLUE_OUTLINE} onClick={props.cancelChangePaymentTermsToCreditCard} />
      </div>
    </form>
  );
}


const ADD_CREDIT_CARD = gql`
  mutation customerAddPaymentMethod($data: PaymentMethodIdInput!) {
    customerAddPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`;

const CHANGE_PAYMENT_METHOD = gql`
  mutation customerChangePaymentMethodToCreditCard($accountId: ID!) {
    customerChangePaymentMethodToCreditCard(accountId: $accountId) {
      errors { key message }
    }
  }
`;


const QUERY = gql`
  query customerBillingShop($accountId: ID!) {
    customerDefaultPaymentMethod(accountId:$accountId) {
      type
    }
    customerSubscriptions(accountId:$accountId) {
      status
    }
  }
`;


const withQueries = compose(
  graphql(QUERY, {
    options: (props) => ({
      variables: {
        accountId: props.accountId
      },
      fetchPolicy: "network-only"
    })
  }),
  graphql(CHANGE_PAYMENT_METHOD, {
    props: ({ mutate }) => ({
      customerChangePaymentMethodToCreditCard: (accountId) => mutate({
        variables: { accountId: accountId }
      })
    })
  }),
  graphql(ADD_CREDIT_CARD, {
    props: ({ mutate }) => ({
      customerAddPaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelChangePaymentTermsToCreditCard, requestAddCreditCard})(withQueries(ChangePaymentTermsToCreditCardFormContainer));


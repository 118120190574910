import React, {useEffect, useState} from "react";
import { isSameAddress } from "../../../lib/AddressRules";
import { useMutation } from "react-apollo";
import { createErrors, createValidationErrors } from "../../../lib/ErrorFormatter";
import Conditional from "../../../shared/Conditional";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import SetupBillingAddressFormContainer, { ANONYMOUS_CHANGE_BILLING_ADDRESS } from "./SetupBillingAddressFormContainer";
import BoxWithRadioSelect from "../../../shop/shared/BoxWithRadioSelect";
import { validateBillingAddress, validateShippingAddress } from "../../../lib/AddressValidation";
import { useTranslation } from "react-i18next";
import { Map } from "immutable";
import Divider from "../../../shop/shared/Divider";


export function BillingAddress(props) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_payment.billing_address"});

  const {
    anonymousCheckoutInfo = Map(),
    className = "",
  } = props;

  const shippingAddress = anonymousCheckoutInfo.get("shippingAddress") || Map();
  const shippingAddressValid = validateShippingAddress(shippingAddress).size === 0;
  const billingAddress = anonymousCheckoutInfo.get("billingAddress") || Map();
  const billingAddressValid = validateBillingAddress(billingAddress).size === 0;
  const [useSameAsShipping, setUseSameAsShipping] = useState(true);
  const [userSelected, setUserSelected] = useState(false);

  // If the accountId is available, it means we are far enough in the onboarding
  // process.
  const isReady = anonymousCheckoutInfo.get("accountId");

  // Set billing to be the same as shipping address mutation:
  const [errors, setErrors] = useState([]);

  // Init selection, even if user refreshes the page
  useEffect(() => {
    if (!userSelected && shippingAddressValid && billingAddressValid) {
      setUseSameAsShipping(isSameAddress(shippingAddress, billingAddress));
    }
  }, [userSelected, shippingAddressValid, billingAddressValid]);

  // Send mutation when user selects "use same as shipping address"
  const [changeBillingAddress] = useMutation(ANONYMOUS_CHANGE_BILLING_ADDRESS);
  useEffect(() => {
    if (isReady && userSelected && useSameAsShipping) {
      // Mutation to use shipping address for billing
      const billingFromShipping = shippingAddress.delete("__typename")
        .delete("addressCountryName")
        .delete("attName")
        .delete("email")
        .delete("phoneNr")
        .delete("type");

      changeBillingAddress({
        variables: {
          data: {
            ...billingFromShipping.toJS(),
            accountId: anonymousCheckoutInfo.get("accountId"),
            anonymousCartId: anonymousCheckoutInfo.get("cartId"),
          }
        }})
        .then((response) => {
          if (response.data.anonymousCheckoutChangeBillingAddress.errors.length > 0) {
            const errors = createValidationErrors(response.data.anonymousCheckoutChangeBillingAddress.errors);
            setErrors(errors);
            setUseSameAsShipping(false); // to display the fiorm with the error
          }
        })
        .catch((err) => {
          setErrors(createErrors(err));
          setUseSameAsShipping(false); // to display the fiorm with the error
        });
    }
  }, [isReady, userSelected, useSameAsShipping]);

  if (!isReady) {
    return <LoadingLine />
  }

  function handleSelect(value) {
    if (!userSelected) {
      // First time
      setUserSelected(true);
    }

    setUseSameAsShipping(value);
  }

  return (
    <div className={className}>
      <BoxWithRadioSelect selected={useSameAsShipping}
        headerText={t("use_same")}
        onSelect={() => handleSelect(true)}>
      </BoxWithRadioSelect>

      <Divider><b className="sg-text-normal u-no-margin">{t("or")}</b></Divider>
      
      <Divider firstOrLast={true} spaceAround={Divider.SPACES.TINY} />
      
      <BoxWithRadioSelect selected={!useSameAsShipping}
        headerText={t("use_different")}
        onSelect={() => handleSelect(false)}>
      </BoxWithRadioSelect>

      <Conditional show={!useSameAsShipping}>
        <SetupBillingAddressFormContainer
          upstreamErrors={errors}
          anonymousCartId={anonymousCheckoutInfo.get("cartId")}
          accountId={anonymousCheckoutInfo.get("accountId")}
          address={anonymousCheckoutInfo.get("billingAddress")}
          prefilledAddress={anonymousCheckoutInfo.get("shippingAddress")}
        />
      </Conditional>
    </div>
  )
}

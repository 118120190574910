import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import TextInputField from "../../../shared/components/forms/TextInputField";
import Divider from "../../../shop/shared/Divider";
import { reduxForm } from "../../../lib/ReduxFormWrapper";
import { change, Field } from "redux-form/immutable";
import Checkbox from "../../../shared/components/forms/Checkbox";
import Button from "../../../shared/components/Button";
import FormError from "../../../shared/components/forms/FormError";
import DropDownField from "../../../shared/components/forms/DropDownField";
import TelephoneInputField from "../../../shared/components/forms/TelephoneInputField";
import normalizePhoneNr from "../../../lib/TelephoneNormalizer";
import { fromJS, List } from "immutable";
import { getStatesOrProvinces } from "../../../lib/CountriesAndStates";
import styles from "../../AnonymousCheckoutPage.module.scss";
import { useDispatch } from "react-redux";
import { INFORMATION_ANCHORS } from "./StepInformation";
import Conditional from "../../../shared/Conditional";


function AnonymousRegistrationForm(props) {
  const { t } = useTranslation("checkout");
  const {
    countryCode,
    errors,
    handleSubmit,
    pristine,
    submitting,
    submitFailed,
    invalid,
    onSignIn,
    status,
    hasRegisteredAlready,
  } = props;

  const dispatch = useDispatch();
  const [initStateProvince, setInitStateProvince] = useState(null);

  const submitLabel = submitting ? t("common:standby") : t("anonymous_checkout.step_information.continue");
  const addressTypes = fromJS([
    {id: "private", label: t("forms:address_residential")},
    {id: "business", label: t("forms:address_commercial")},
  ]);

  const statesOrProvinces = filterStatesOrProvinces(countryCode);

  let stateProvinceInput = null;
  if (statesOrProvinces.size > 0) {
    // Because this field is instantiated later the initialValues do not work
    // and we need to initialize it by hand here.
    const initialValue = props.initialValues.get("addressStateProvince")
    if (initialValue && !initStateProvince) {
      setInitStateProvince(initialValue);
    }

    stateProvinceInput = <Field
      label={t("forms:state_province")}
      name="addressStateProvince"
      mode=""
      component={DropDownField}
      options={statesOrProvinces}
      autoComplete="address-level1"
      valueKey="code"  
      titleKey="name"
      serverError={errors.addressStateProvince}
      />
  }

  useEffect(() => {
    if (initStateProvince) {
      console.log("SETTING STATE PROVINCE", initStateProvince);
      dispatch(change("AnonymousRegistrationForm", "addressStateProvince", initStateProvince));
    }
  }, [initStateProvince]);

  // Allow submitting from parent element
  useEffect(() => {
    if (status === "submit") {
      props.submit();
    }
  }, [status]);
  
  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      <div className={styles.accountTitle}>
        <h1 id={INFORMATION_ANCHORS.ACCOUNT}>{t("anonymous_checkout.step_information.account.title")}</h1>
        <Conditional show={!hasRegisteredAlready}>
          {/* Only show login option if the user didn't get past the anonymous registration */}
          <div className={styles.login}>
            <Trans i18nKey="checkout:anonymous_checkout.step_information.account.have_an_account"
              components={{
                a: <Button
                  className="sg-font-bold sg-text-normal u-no-margin"
                  theme={Button.THEMES.BLUE}
                  onClick={() => onSignIn()} link/>
              }} />
          </div>
        </Conditional>
      </div>
      <TitleDivider />

      <p className="sg-text-small">{t("anonymous_checkout.step_information.account.notice")}</p>

      {/* Account fields */}
      <div className="fields">
        <Field
          label={t("forms:email")}
          name="email"
          mode=""
          placeholder={t("forms:email_placeholder")}
          autoComplete="email"
          component={TextInputField}
          className={styles.emailField}
          serverError={errors.email}
          noTable={true}
        />
        <Field
          label={<span>{t("anonymous_checkout.step_information.account.email_notice")}</span>}
          name="marketingAccepted"
          component={Checkbox}
          serverError={errors.marketingAccepted}
          noTable={true}
        />
      </div>

      <Divider firstOrLast={true} spaceAround={Divider.SPACES.NORMAL} />

      <h1 id={INFORMATION_ANCHORS.SHIPPING_ADDRESS}>{t("anonymous_checkout.step_information.shipping_address.title")}</h1>
      <TitleDivider />

      <p className="sg-text-small">{t("anonymous_checkout.step_information.shipping_address.notice")}</p>

      {/* Shipping address fields */}
      <div className="fields">
        <Field
          label={t("forms:address_residential_or_commercial")}
          name="type"
          component={DropDownField}
          options={addressTypes}
          mode=""
          valueKey="id"  
          titleKey="label" 
          serverError={errors.type}
          />
        <Field
          label={t("common:shipping.recipient_name")}
          name="name"
          placeholder={t("forms:full_name_placeholder")}
          mode=""
          autoComplete="name"
          component={TextInputField}
          serverError={errors.name}
          />
        <Field
          label={t("common:shipping.recipient_attn")}
          name="attName"
          mode=""
          placeholder=""
          component={TextInputField}
          serverError={errors.attName}
          />
        <Field
          label={t("common:shipping.recipient_phone")}
          name="phoneNr"
          mode=""
          normalize={normalizePhoneNr}
          autoComplete="tel"
          component={TelephoneInputField}
          defaultCountryCode={countryCode}
          loading={!countryCode}
          serverError={errors.phoneNr}
          />
        {/* No country field because the cart was done for a specific country already
        If we want to add the country selector, we could do it with a filter for
        countries in the same jurisdiction, or alternatively depending on which
        products are in the cart, for countries where they are available. */}
          
        <Field
          label={t("forms:address_line_1")}
          name="addressAddressLine1"
          placeholder={t("forms:address_line_1_placeholder")}
          mode=""
          autoComplete="address-line1"
          component={TextInputField}
          serverError={errors.addressAddressLine1}
          />
        <Field
          label={t("forms:address_line_2")}
          name="addressAddressLine2"
          placeholder={t("forms:address_line_2_placeholder")}
          mode=""
          autoComplete="address-line2"
          component={TextInputField}
          serverError={errors.addressAddressLine2}
          />
        <Field
          label={t("forms:city")}
          name="addressCity"
          placeholder={t("forms:city_placeholder")}
          mode=""
          autoComplete="address-level2"
          component={TextInputField}
          serverError={errors.addressCity}
          />
        <Field
          label={t("forms:post_code")}
          name="addressPostcode"
          placeholder={t("forms:post_code_placeholder")}
          mode=""
          autoComplete="postal-code"
          normalize={toUpperCase}
          component={TextInputField}
          serverError={errors.addressPostcode}
          />

        {stateProvinceInput}
      </div>

      <FormError error={errors._error} validationError={submitFailed && invalid} />

      <div className={styles.buttons}>
        <Button onClick={handleSubmit(values => props.onSubmit(values, pristine))}
          size={Button.SIZES.MEDIUM}
          primary={true} label={submitLabel} active={!submitting}
        />

        <Button onClick={props.onCancel} link
          theme={Button.THEMES.GREY_OUTLINE}>
          {t("anonymous_checkout.step_information.return")}
        </Button>
      </div>
    </form>
  )
}

function TitleDivider() {
  return <Divider style={{marginTop: "8px", marginBottom: "12px"}} />
}

function toUpperCase(value) {
  return value && value.toUpperCase();
}

// Some state or province can not be selected for delivery
const filterStatesOrProvinces = (addressCountryCode) => {
  let states = getStatesOrProvinces(addressCountryCode) || List([]);

  const code = String(addressCountryCode).substr(0,2).toLowerCase();
  if (code === "us") {
    states = states.filter((s) => s.get("code") !== "VI");
  }

  return states;
}

export default reduxForm({
  form: "AnonymousRegistrationForm",
  destroyOnUnmount: false,
  // Allow update of fields through changes to initialValues
  enableReinitialize: true,
  // Prevent such updates from impacting fiels that were edited by the user
  keepDirtyOnReinitialize: true,
})(AnonymousRegistrationForm);
import React, {useEffect, useState} from "react";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import styles from "./StripeElements.module.scss";
import { handleStripeConfirmSetup } from "../../lib/StripeHelper";
import Conditional from "../../shared/Conditional";
import { noop } from "lodash";


export default function NewPaymentElementForm(props) {
  const { t } = useTranslation("common");

  const {
    showSubmitButton = true,
    submitting,
    clientSecret,
    textClass = "u-centered",
    buttonsClass = "buttons",
    status, // Used when we need a parent to trigger the form submission
    onError = noop,
    onSuccess = noop,
    submitErrors,
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  
  const [stripeErrorMessage, psetStripeErrorMessage] = useState(null);
  const [submittingToken, setSubmittingToken] = useState(false);
  const [stripeFocused, setStripeFocused] = useState(false);
  const [stripeErrored, setStripeErrored] = useState(false);
  function setStripeErrorMessage(message) {
    psetStripeErrorMessage(message);
    onError(message);
  }

  async function handleSubmit(event) {
    event && event.preventDefault();

    const callbacks = {
      t,
      setStripeErrorMessage,
      addPaymentMethod: props.onSubmit,
      onSuccess,
      setSubmittingToken,
    }

    handleStripeConfirmSetup(clientSecret, {stripe, elements}, callbacks)
  }

  const active = !(submitting || submittingToken);
  const submitLabel = active ? t("credit_card.add") : t("standby");

  const stripeElementClasses = [
    "StripeElement",
    styles.stripeElement,
  ];
  if (stripeFocused) {
    stripeElementClasses.push(styles.stripeFocused);
  }
  if (stripeErrored) {
    stripeElementClasses.push(styles.stripeErrored);
  }

  // Allow submitting from parent element
  useEffect(() => {
    if (status === "submit") {
      handleSubmit();
    }
  }, [status]);

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <div className="fields">
        <p className={textClass}>
          <Trans
            i18nKey="common:payment_element.authorize_text"
            components={{
              b: <span className="sg-font-bold"/>
            }}
            values={{
              shopName: props.shopName,
            }}
          />
        </p>
        <PaymentElement
          options={{
            readOnly: !active,
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: true,
            },
          }}      
          onFocus={() => setStripeFocused(true)}
          onBlur={() => setStripeFocused(false)}
          onChange={(event) => {
            const inError = !!event.error;
            if (stripeErrored !== inError) {
              setStripeErrored(inError);
            }}
          }
          className={stripeElementClasses.join(" ")}
        />
      </div>
      <Conditional show={showSubmitButton}>
        <div className={buttonsClass}>
          <Button label={submitLabel} primary={true} onClick={handleSubmit} active={active} />
          { props.onCancel && (
              <Button label={t("cancel")} active={active} onClick={props.onCancel}
                theme={Button.THEMES.BLUE_OUTLINE}
                addCancelToTopRight={true} />)
          }
        </div>
      </Conditional>

      <FormError error={stripeErrorMessage} />
      <FormError error={submitErrors && submitErrors._error} />
    </form>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styles from "./AccountSubscriptions.module.scss";
import SubscriptionPanel from "./SubscriptionPanel";
import SimpleCard, { simpleCardConfigFromPrismic } from "../../shared/components/SimpleCard";
import { List } from "immutable";
import useResizeListener from "../../hooks/useResizeListener";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";


export default function AccountSubscriptions(props) {
  const {
    accountId,
    subscriptions,
    noSubscriptionsCard,
    decks = {},
    handleViewDeliveries, handleViewCharges,
  } = props;

  const moduleRef = useRef();
  const { width } = useResizeListener(window.document.body);
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (width > 0 && subscriptions) {
      // Put more or less items per row depending on viewport size
      const {
        essentialCards = List(),
        optionalCards = List(),
      } = decks;
      const mandatoryItems = subscriptions.size + essentialCards.size;
      let newItemsPerRow = 0;
      let optionalCardsToTake = 0;
      
      if (width > 1250) {
        newItemsPerRow = 3;
        optionalCardsToTake = 3 - (mandatoryItems % 3);
        if (optionalCardsToTake === 3) {
          optionalCardsToTake = 0;
        }
      } else if (width > 700 && width < 1250) {
        newItemsPerRow = 2;
        optionalCardsToTake = mandatoryItems % 2;
      } else if (width < 700) {
        newItemsPerRow = 1;
        optionalCardsToTake = 0;
      }
      
      setCards(essentialCards.concat(optionalCards.take(optionalCardsToTake)).map((c, i) =>
        <SimpleCard
          key={i}
          {...simpleCardConfigFromPrismic(c)}
          className={[
            c.get("essential") ? styles.essential : "",
            c.get("optional") ? styles.optional : "",
          ].join(" ")}
        />
      ));
      setItemsPerRow(newItemsPerRow);
    }
  }, [width, subscriptions, decks])

  if (!subscriptions) {
    return <div className={styles.loading}><LoadingCircle /></div>;
  }
  
  if (subscriptions.size === 0) {
    // noSubscriptionCard could still be downloading from prismic
    return !noSubscriptionsCard ? null : (
      // TODO gift card
      <SimpleCard className={styles.noSubscriptionsBanner} {...simpleCardConfigFromPrismic(noSubscriptionsCard)} />
    );
  }

  const subs = subscriptions.map((x, i) =>
    <SubscriptionPanel
      subscription={x}
      accountId={accountId}
      subscriptionId={x.get("subscriptionId")}
      id={i+1}
      key={x.get("subscriptionId")} 
      onViewDeliveries={handleViewDeliveries}
      onViewPayments={handleViewCharges}
    />
  );

  return (
    <div className={styles.module} ref={moduleRef} style={{"--items-per-line": itemsPerRow}}>
      { subs }
      { cards }
    </div>
  );
}

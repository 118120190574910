import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Form from "./AnonymousRegistrationForm";
import { validateEmail, validatePhoneNumber, validatePresence } from "../../../lib/Validators";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";
import { getCompletePhoneNrOrNothing } from "../../../lib/TelephoneNormalizer";
import { getReferralCodeFromUrl } from "../../../onboarding/views/Registration";
import useQueryMap from "../../../hooks/useQueryMap";
import { createErrors, createValidationErrors } from "../../../lib/ErrorFormatter";
import { Map } from "immutable";
import { betterErrorMessages, informationDataToCheckoutInfo } from "./SaveInformationHelper";


function validateForm(values) {
  let errors = Map();
  errors = validateEmail(errors, values, "email");

  // Shipping address specific
  errors = validatePresence(errors, values, "type");
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "phoneNr");
  errors = validatePhoneNumber(errors, values, "phoneNr");
  errors = validatePresence(errors, values, "addressAddressLine1");
  errors = validatePresence(errors, values, "addressCity");
  errors = validatePresence(errors, values, "addressPostcode");
  errors = validatePresence(errors, values, "addressStateProvince");

  return errors.toJS();
}

function AnonymousRegistrationFormContainer(props) {
  const { i18n } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_information"});
  const query = useQueryMap();

  const {
    anonymousCheckoutInfo,
    onSignIn,
    anonymousCheckoutSaveInformation,
    status,
    onError,
    onSuccess,
    onCancel,
    stripePaymentMethod = "", // From fast checkout
  } = props;

  const [errors, setErrors] = useState({});
  const [displayedLoginAdvice, setDisplayedLoginAdvice] = useState(false);

  const countryCode = anonymousCheckoutInfo.get("countryCode");
  const hasRegisteredAlready = !!anonymousCheckoutInfo.get("onboardingId");

  function handleSubmit(values, isPristine = false) {
    if (!anonymousCheckoutInfo.get("cartId")) {
      onError();
      return console.error("No cart ID");
    }

    if (isPristine && hasRegisteredAlready) {
      // onboardingId means that the user is registered or registering, and can
      // access the second step of the checkout. So we consider this a success
      // without submitting the form if the form wasn't modified.
      console.debug("Pristine form, going to next step")
      onSuccess(); // nothing to update in the cart
      return;
    }

    let updatedValues = Map({
      anonymousCartId: anonymousCheckoutInfo.get("cartId"),
      email: values.get("email", ""),
      tacAccepted: true, // Text has to be present in the checkout to say that the user agrees
      marketingAccepted: values.get("marketingAccepted") === "yes",
      locale: i18n.language,
      countryCode: countryCode,
      referralCode: getReferralCodeFromUrl(query), // Can only come from query (field isn't used),
      gclid: query.get("gclid", ""),
      fbclid: query.get("fbclid", ""),
      paymentMethodId: stripePaymentMethod,
      // Address specific
      type: values.get("type", ""),
      name: values.get("name", ""),
      attName: values.get("attName", ""),
      phoneNr: getCompletePhoneNrOrNothing(values.get("phoneNr", "")),
      addressCountryCode: countryCode,
      addressAddressLine1: values.get("addressAddressLine1", ""),
      addressAddressLine2: values.get("addressAddressLine2", ""),
      addressCity: values.get("addressCity", ""),
      addressPostcode: values.get("addressPostcode", ""),
      addressStateProvince: values.get("addressStateProvince", ""),
    });

    return anonymousCheckoutSaveInformation(updatedValues.toJS())
      .then((response) => {
        if (response.data.anonymousCheckoutSaveInformation.errors.length <= 0) {
          // updatedCartData has to follow "anonymousCheckoutInfo" query fields format
          const onboardingId = response.data.anonymousCheckoutSaveInformation.onboardingId;

          onSuccess(informationDataToCheckoutInfo(onboardingId, updatedValues));
          // registered(response.data.anonymousCheckoutSaveInformation.onboardingId, updatedValues.get("email"), updatedValues.get("password"));
        } else {
          const errors = createValidationErrors(response.data.anonymousCheckoutSaveInformation.errors);
          onError();
          const betterErrors = betterErrorMessages(errors);

          // Special handling for customers whose email is already registered
          if (!displayedLoginAdvice && betterErrors?.email === "This email is already being used") {
            setDisplayedLoginAdvice(true);
            onSignIn(values.get("email", ""));
          }
          setErrors(betterErrors);
        }
      })
      .catch((err) => {
        onError();
        setErrors(createErrors(err));
      });
  }

  const initialValues = {
    type: anonymousCheckoutInfo.getIn(["shippingAddress", "type"]) || "private",
    name: anonymousCheckoutInfo.getIn(["shippingAddress", "name"]) || "",
    marketingAccepted: anonymousCheckoutInfo.get("marketingAccepted") ? "yes" : "no",
    attName: anonymousCheckoutInfo.getIn(["shippingAddress", "attName"]) || "",
    phoneNr: anonymousCheckoutInfo.getIn(["shippingAddress", "phoneNr"]) || "",
    email: anonymousCheckoutInfo.getIn(["shippingAddress", "email"]) || "",
    addressAddressLine1: anonymousCheckoutInfo.getIn(["shippingAddress", "addressAddressLine1"]) || "",
    addressAddressLine2: anonymousCheckoutInfo.getIn(["shippingAddress", "addressAddressLine2"]) || "",
    addressCity: anonymousCheckoutInfo.getIn(["shippingAddress", "addressCity"]) || "",
    addressPostcode: anonymousCheckoutInfo.getIn(["shippingAddress", "addressPostcode"]) || "",
    addressStateProvince: anonymousCheckoutInfo.getIn(["shippingAddress", "addressStateProvince"]) || "",
    addressCountryCode: anonymousCheckoutInfo.getIn(["shippingAddress", "addressCountryCode"]) || "",
    addressCountryName: anonymousCheckoutInfo.getIn(["shippingAddress", "addressCountryName"]) || "",
  }
  
  return (
    <Form
      errors={errors}
      validate={validateForm}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      onSignIn={onSignIn}
      countryCode={countryCode}
      initialValues={initialValues}
      status={status}
      onError={onError}
      hasRegisteredAlready={hasRegisteredAlready}
    />
  )
}

const SAVE_INFORMATION = gql`
  mutation anonymousCheckoutSaveInformation($data: StartOnboardingInput!) {
    anonymousCheckoutSaveInformation(input: $data) {
      onboardingId
      errors { key message }
    }
  }
`;


const withMutation = compose(
  graphql(SAVE_INFORMATION, {
    props: ({ mutate }) => ({
      anonymousCheckoutSaveInformation: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withMutation(AnonymousRegistrationFormContainer);

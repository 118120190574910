import { useEffect } from "react";
import { validateShippingAddress } from "../../../lib/AddressValidation";
import { createErrors, createValidationErrors } from "../../../lib/ErrorFormatter";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";
import { noop } from "lodash";


function SaveShippingAddressWhenReady(props) {
  const {
    anonymousCheckoutInfo,
    savedInformationData,
    // Mutation
    anonymousCheckoutSaveShippingAddress,
    onSuccess = noop,
    onError = noop,
  } = props;

  // Save shipping address once the sales order is ready to accept those
  const shippingAddressProvided = anonymousCheckoutInfo.get("shippingAddressStatus") !== "not_provided";
  const canChangeAddress = anonymousCheckoutInfo.get("canChangeAddress");

  useEffect(() => {
    if (!savedInformationData || savedInformationData.size === 0) return;

    const values = savedInformationData.get("shippingAddress")
      .set("anonymous_cart_id", anonymousCheckoutInfo.get("cartId"));

    if (!shippingAddressProvided && canChangeAddress && validateShippingAddress(values).size === 0) {
      anonymousCheckoutSaveShippingAddress(values.toJS())
      .then((response) => {
        if (response.data.anonymousCheckoutSaveShippingAddress.errors.length <= 0) {
          onSuccess();
        } else {
          let errors = createValidationErrors(response.data.anonymousCheckoutSaveShippingAddress.errors);
          onError(errors);
        }
      })
      .catch((err) => {
        onError(createErrors(err));
      });
    }
  }, [canChangeAddress, shippingAddressProvided, savedInformationData]);

  return null;
}


const SAVE_INFORMATION = gql`
  mutation anonymousCheckoutSaveShippingAddress($data: StartOnboardingInput!) {
    anonymousCheckoutSaveShippingAddress(input: $data) {
      errors { key message }
    }
  }
`;

const withMutation = compose(
  graphql(SAVE_INFORMATION, {
    props: ({ mutate }) => ({
      anonymousCheckoutSaveShippingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withMutation(SaveShippingAddressWhenReady);

import { useEffect, useState } from "react";
import {useLocation} from "react-router-dom";
import useResizeListener from "./useResizeListener";

export default function useScrollToHash(myRef) {
  const { hash } = useLocation();
  const [userScrolled, setUserScrolled] = useState(false);
  const { height } = useResizeListener(window.document.body);

  useEffect(() => {
    // Whenever the hash changes, but ignore when hash is empty
    setUserScrolled(!hash);
    
    if (!hash) return;

    const handleScroll = () => setUserScrolled(true);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [hash]);

  useEffect(() => {
    // We want to navigate to hash only if the user didn't scroll at all
    if (hash && !userScrolled) {
      const id = hash.replace("#", "");
      const elem = window.document.getElementById(id);

      if (elem) {
        elem.scrollIntoView();
        // Scrolling into view triggers the 'scroll' event so we compensate for
        // that with a small delay
        window.setTimeout(() => setUserScrolled(false), 200);
      }
    }
  }, [height, hash, userScrolled]);

  return null; // Don't care
}
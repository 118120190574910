import React, {Component} from "react";
import {connect} from "react-redux";
import {
  requestAddCreditCard,
  requestChangeCreditCard,
  requestChangePaymentTermsToCreditCard,
  requestChangeBillingAddress,
  requestModifyBilling,
  requestSetTaxInfo,
  } from "../../actions/AccountActions";
import Navigation from "../../lib/Navigation";
import AddressInline from "../../shared/AddressInline";
import Button from "../../shared/components/Button";
import CreditCardPaymentMethod from "./CreditCardPaymentMethod";
import Panel from "../shared/Panel";
import Subpanel from "../shared/Subpanel";
import StatusTag from "../../shared/components/StatusTag";
import styles from "./BillingPanel.module.scss";
import Checked from "../shared/icons/Checked";
import { withTranslation, Trans } from 'react-i18next';

class BillingPanel extends Component {

  constructor(props) {
    super(props);
    this.handleAddCard = this.handleAddCard.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);
    this.handleChangePaymentTermsToCreditCard = this.handleChangePaymentTermsToCreditCard.bind(this);
    this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
    this.handleSetTaxInfo = this.handleSetTaxInfo.bind(this);
    this.handleAddAccount = this.handleAddAccount.bind(this);
    this.handleModifyBilling = this.handleModifyBilling.bind(this);
  }

  handleAddCard() {
    this.props.requestAddCreditCard(this.props.accountId);
  }

  handleChangeCard() {
    this.props.requestChangeCreditCard(this.props.accountId);
  }

  handleChangePaymentTermsToCreditCard() {
    this.props.requestChangePaymentTermsToCreditCard(this.props.accountId);
  }

  handleChangeBillingAddress(isCreation = false) {
    this.props.requestChangeBillingAddress(this.props.accountId, isCreation);
  }

  handleSetTaxInfo() {
    this.props.requestSetTaxInfo(this.props.accountId);
  }

  handleModifyBilling() {
    this.props.requestModifyBilling(this.props.accountId);
  }

  handleAddAccount() {
    Navigation.gotoSelectAccount();
  }


  render() {
    // 2 cases: billing address and payment method
    const renderBilling = this.props.billingAddressStatus || this.props.billingAddress;
    const renderCreditCard = this.props.paymentMethod === "credit_card";
    const renderPaymentTerms = this.props.paymentMethod === "payment_terms";

    return (
      <React.Fragment>
        {renderBilling && this.renderBillingAddressPanel()}
        {renderCreditCard && this.renderCreditCardPanel()}
        {renderPaymentTerms && this.renderPaymentTermsPanel()}
      </React.Fragment>
    );
  }


  renderBillingAddressPanel() {
    let billingAddress = this.props.billingAddress;
    let billingAddressStatus = this.props.billingAddressStatus;
    let taxId = this.props.taxId;
    return (
      <Panel title={this.props.t("accounts:billing.name")}
        subtitle={this.props.t("billing.subtitle", { ns: "accounts" })}
        subpanels={
          this.renderBillingAddress(billingAddressStatus, billingAddress, taxId, this.props.validTaxId, this.props.taxExempt)
        }
        loading={this.props.loading} />
    );
  }

  renderCreditCardPanel() {
    const { defaultPaymentMethod } = this.props;
    
    let button = null;
    let title = "";

    if (this.props.paymentMethod === "credit_card") {
      if (defaultPaymentMethod) {
        button = <Button label={this.props.t("credit_card.edit")} theme={Button.THEMES.BLUE_OUTLINE} onClick={this.handleChangeCard} />;
        title = this.props.t("credit_card.name");
      } else {
        button = <Button label={this.props.t("credit_card.add")} theme={Button.THEMES.BLUE_OUTLINE} onClick={this.handleAddCard} />;
        title = "";
      }
    }

    const content = defaultPaymentMethod && (
      <div className={styles.cardCard}>
        <CreditCardPaymentMethod
          defaultPaymentMethod={defaultPaymentMethod} />
      </div>
    );

    const subpanel = (
      <Subpanel
        title={title}
        content={content}
        button={button} />
    );

    return (
      <Panel title={this.props.t("accounts:payment_method.name")}
      subtitle={this.props.t("accounts:payment_method.subtitle")}
        subtitleClasses={styles.stripeBackground}
        subpanels={subpanel}
        subpanelsCount={1}
        loading={this.props.loading} />
    );
  }

  renderPaymentTermsPanel() {
    const { status } = this.props;

    const isDelinquent = status === "delinquent";
    const content = isDelinquent
      ? <p className="delinquencyNotice">{this.props.t("credit_card.delinquent_notice")}</p>
      : <p>
          <Trans i18nKey="common:credit_card.payment_terms"
            count={this.props.paymentTermsDays}
            components={{
              b: <strong />
            }}
          />
        </p>;

    const button = <Button label={this.props.t("credit_card.switch_to_cc")} theme={Button.THEMES.BLUE_OUTLINE} onClick={this.handleChangePaymentTermsToCreditCard} />;
    const title = "";

    const subpanel = (
      <Subpanel
        title={title}
        content={content}
        button={button} />
    );

    return (
      <Panel title={this.props.t("accounts:payment_method.name")}
      subtitle={this.props.t("accounts:payment_method.subtitle")}
        subtitleClasses={styles.stripeBackground}
        subpanels={subpanel}
        subpanelsCount={1}
        loading={this.props.loading} />
    );
  }

  renderBillingAddress(status, billingAddress, taxId, validTaxId, taxExempt) {
    if (status !== "provided") {
      return (
        <Subpanel title={this.props.t("billing.address")}
          subtitle={this.props.t("billing.address_missing")}
          button={<Button label={this.props.t("billing.add_address")}
            onClick={() => this.handleChangeBillingAddress(true)}
            theme={Button.THEMES.BLUE_OUTLINE} />} />
      )
    }

    let taxIdStatus = null;
    let taxButtonLabel = this.props.t("billing.add_tax_id");
    let taxIdVerified = null;
    if (validTaxId || true) {
      taxIdVerified = (
        <StatusTag color={StatusTag.COLORS.GREEN} text={this.props.t("billing.verified")}>
          <Checked outline />
        </StatusTag>
      );
    }
    if (taxId && taxId !== "") {
      taxIdStatus = <p key="taxIdStatus" className={styles.taxData}>{taxId} &nbsp; {taxIdVerified}</p>;
      taxButtonLabel = this.props.t("billing.edit_tax_id");
    }

    let taxExemptionStatus = null;
    if (taxExempt) {
      taxExemptionStatus = <p key="taxExemptionStatus">{this.props.t("billing.tax_exempt")}.</p>;
    }

    const addressContent = (
      <AddressInline
        showType={false}
        address={billingAddress} />
    );
    const modifyAddressButton =
      <Button label={this.props.t("billing.edit_address")}
        onClick={() => this.handleChangeBillingAddress(false)}
        theme={Button.THEMES.BLUE_OUTLINE} />;

    const taxIdContent = (
      <React.Fragment>
        {taxIdStatus}
        {taxExemptionStatus}
      </React.Fragment>
    );
    const modifyTaxIdButton =
      <Button label={taxButtonLabel} className={styles.taxButton}
        onClick={this.handleSetTaxInfo}
        theme={Button.THEMES.BLUE_OUTLINE} />;

    return (
      <React.Fragment>
        <Subpanel title={this.props.t("billing.address")} content={addressContent} button={modifyAddressButton} />
        <Subpanel title={this.props.t("billing.tax_id")} content={taxIdContent} button={modifyTaxIdButton} />
      </React.Fragment>
    )
  }

}

export default withTranslation(["common", "accounts"])(connect(null, {
  requestAddCreditCard,
  requestChangeCreditCard,
  requestChangePaymentTermsToCreditCard,
  requestChangeBillingAddress,
  requestModifyBilling,
  requestSetTaxInfo
})(BillingPanel));

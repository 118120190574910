import {Map, fromJS} from "immutable";
import {isUS, isCanada, isSpain} from "./CountriesAndStates";

export const hasStateProvince = (countryCode) => {
  return isUS(countryCode) || isCanada(countryCode) || isSpain(countryCode);
}

export const hasStateProvinceForAddress = (address) => {
  return hasStateProvince(address.get("addressCountryCode", ""))
}

export const applyStateProvinceRule = (address) => {
  if (Map.isMap(address)) {
    if (hasStateProvinceForAddress(address)) return address;
    return address.set("addressStateProvince", "");
  } else {
    let theAddress = fromJS(address);
    if (hasStateProvinceForAddress(theAddress)) return address;
    return theAddress.set("addressStateProvince", "").toJS();
  }
}

export const isSameAddress = (address1, address2) => {
  const propsToCheck = [
    "name",
    "addressAddressLine1",
    "addressAddressLine2",
    "addressCity",
    "addressPostcode",
    "addressStateProvince",
    "addressCountryCode",
  ];
  return propsToCheck.every((prop) => {
    return address1.get(prop, "") === address2.get(prop, "");
  })
}

import React from "react";
import styles from "./BasketWithTag.module.scss";
import Basket from "./icons/Basket";


export default function BasketWithTag(props) {
  const {
    count,
    onClick,
    basketColor = "var(--grey-900)",
    basketClassName = null,
    side = "24px",
  } = props;

  return (
    <div className={styles.module}>
      <Basket side={side} color={basketColor} onClick={onClick}
        className={basketClassName} />
      <RoundedTag count={count} />
    </div>
  )
}


function RoundedTag({count = 0}) {
  if (count <= 0) return null;

  const classes = [
    styles.rounded,
    count >= 10 ? styles.bigger : "",
  ];
  
  return (
    <div className={classes.join(" ")}>{count}</div>
  );
}
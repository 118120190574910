import { fromJS } from "immutable";



export function informationDataToCheckoutInfo(onboardingId, values) {
  return fromJS({
    onboardingId: onboardingId, // Important to unlock the payment step
    email: values.get("email"),
    name: values.get("name"),
    marketingAccepted: values.get("marketingAccepted"),
    shippingAddress: {
      type: values.get("type"),
      name: values.get("name"),
      attName: values.get("attName"),
      phoneNr: values.get("phoneNr"),
      email: values.get("email"),
      addressCountryCode: values.get("addressCountryCode"),
      addressAddressLine1: values.get("addressAddressLine1"),
      addressAddressLine2: values.get("addressAddressLine2"),
      addressCity: values.get("addressCity"),
      addressPostcode: values.get("addressPostcode"),
      addressStateProvince: values.get("addressStateProvince"),
    }
  });
}


// TODO translate
export function betterErrorMessages(errors) {
  if (errors.addressCountryCode === "is not available for shipment") {
    errors._error = "This address is not available for delivery";
  } else if (errors.email === "This email is already being used") {
    errors._error = "This email is already taken. Are you sure you do not have an account already?";
  }

  return errors;
}
import React, { useEffect, useRef, useState } from "react";
import FieldError from "./FieldError";
import styles from "./TelephoneInputField.module.scss";
import normalizePhoneNr, { getCountryCallingCodeFromISO2 } from "../../../lib/TelephoneNormalizer";
import "./FlagsSprites.css";

// Handle country select ourselves
import PhoneInput, { getCountries, parsePhoneNumber, getCountryCallingCode, formatPhoneNumber } from 'react-phone-number-input/input';
import Select from "./Select";
import { fromJS } from "immutable";
import { COUNTRY_CODE_KEY, COUNTRY_LABEL_KEY, filterAndSortCountries } from "../../../lib/CountriesAndStates";
import { useTranslation } from "react-i18next";

const FALLBACK_COUNTRY_CODE = "US";

const initCountriesMap = () => {
  const countriesCodes = getCountries();

  return fromJS(filterAndSortCountries(countriesCodes));
}

const initCountryCode = (initialPhone, defaultCountryCode) => {
  // Priorities:
  // 1/ if the phone has a valid international value, use its country code
  // 2/ use the defaultCountryCode, if any
  // 3/ use the fallback country code
  const parsed = initialPhone && parsePhoneNumber(initialPhone);

  return parsed && parsed.country
    ? parsed.country
    : defaultCountryCode || FALLBACK_COUNTRY_CODE;
}

const areCallingCodesCoherent = (countryCode) => {
  // Sometimes getCountryCallingCodeFromISO2 returns null, and in those
  // cases we trust getCountryCallingCode.
  const callingCode = getCountryCallingCodeFromISO2(countryCode);
  return callingCode === null || callingCode === getCountryCallingCode(countryCode);
}

const titleFromCode = (countryCode, originalTitle) => {
  // getCountryCallingCode doesn't always return the right country code,
  // as for some small areas it returns "+1" instead of e.g. "+1456".
  // This said, it's better to work with this now and use that +1 next to the
  // flag, and the "456" in the local part of the phone number.
  // See resetPhoneNumber
  const callingCode = getCountryCallingCode(countryCode);
  
  return "+" + callingCode;
}

const guessCountryCodeFromParsedNumber = (parsed) => {
  let countryCode = parsed.country;

  if (!countryCode && parsed.metadata.country_calling_codes[parsed.countryCallingCode]) {
    // Take advantage of metadata
    // In country_calling_codes, we can find a list of country codes indexed
    // by their calling codes. Most of the times there is only one element in
    // the list (e.g. ["FR"] for +33), but there can be many (e.g.
    // ["GB", "GG", "IM", "JE"] for +44, and many more for +1).
    // We grab the first of that list as we don't know better anyway, which
    // happens only when the number is partially written.
    countryCode = parsed.metadata.country_calling_codes[parsed.countryCallingCode][0];
  }
  
  return countryCode;
}

const TelephoneInputField = (props) => {
  const { t } = useTranslation("forms");
  const {
    serverError, label, active, darkBackground,
    defaultCountryCode,
    input: {value, onChange},
    meta: {touched, error}
  } = props;

  const [countriesMap] = useState(initCountriesMap) // Only set once
  const [focused, setFocused] = useState(false);
  // Hanlde country select ourselves
  const [countryCode, setCountryCode] = useState(initCountryCode(value, defaultCountryCode));
  const [phoneNumber, setPhoneNumber] = useState(value || "");
  const handleCountryFocus = (e) => setFocused(true);
  const handleCountryBlur = (e) => setFocused(false);
  const handlePhoneFocus = (e) => setFocused(true);
  const handlePhoneBlur = (e) => {
    setFocused(false);
    
    // Make sure to call onChange on blur
    onChange(phoneNumber);
  }

  useEffect(() => {
    if (defaultCountryCode && phoneNumber === "") {
      // Update the default if no phone has been defined yet
      setCountryCode(defaultCountryCode);
    }
  }, [defaultCountryCode]);

  // HANDLE BUG FOR COUNTRIES WHOSE DIAL CODE IS GUESSED WRONG
  // Basic idea: add a ref to the phone input and redefine the input's
  // getter and setter, in order to be able to know what's written in there.
  // This is because in the onChange callback, the lib only gives us the number
  // it thinks we need (but it's buggy as it adds calling code prefixes when it
  // should not, transforming the input "(246) 333-3333" into "(246) 246-3333333"
  const refInput = useRef(null);
  const [phoneNumberHack, setPhoneNumberHack] = useState(value || "");
  useEffect(() => {
    const { get, set } = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
    Object.defineProperty(refInput.current, 'value', {
      get() {
        return get.call(this);
      },
      set(newVal) {
        setPhoneNumberHack(newVal);
        return set.call(this, newVal);
      }
    });
  }, [refInput]);

  const handleCountryChange = (countryCode) => {
    setCountryCode(countryCode);
  };
  useEffect(() => {
    // Updating the country should of course update the current phone number
    // dial code, and keep its local part as is.
    const callingPrefix = "+" + getCountryCallingCode(countryCode);

    if (phoneNumber.startsWith("+") && !phoneNumber.startsWith(callingPrefix)) {
      const numberForNewCountry = normalizePhoneNr("+" + callingPrefix + formatPhoneNumber(phoneNumber));

      setPhoneNumber(numberForNewCountry);
      onChange(numberForNewCountry); // Update the input itself
    }

    if (!areCallingCodesCoherent(countryCode)) {
      // Those numbers have some of their dialing code in the local part
      // of the phone number, so we force reset to this.
      resetPhoneNumber(countryCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const resetPhoneNumber = (countryCode) => {
    const value = areCallingCodesCoherent(countryCode)
      ? ""
      : "+" + getCountryCallingCodeFromISO2(countryCode);

    setPhoneNumber(value);
    onChange(value);
    return null;
  }

  let handlePhoneChange = (value) => {
    if (!active) {
      return null;
    }

    // We want to save the phone number into the state, but before that we
    // need to check whether the inputted value should change the country code
    // or not. 2 cases:
    // * User writing the phone number with a country dial code different from
    //   the current country code dial. E.g. +33 5 65...
    // * User writing a local phone number which shares the same dial code, as
    //   many countries share some dial codes like +1.
    // Allow user pressing "+" to replace the old number (value is not defined
    // until the user types in more number).
    if (phoneNumberHack === "+") {
      setPhoneNumber("+")
      return null;
    }
    
    if (value === null || value === undefined) {
      return resetPhoneNumber(countryCode);
    }

    if (!areCallingCodesCoherent(countryCode)) {
      // This is to circumvent the bug described before "refInput".
      // Use the input even listener to define the value in order to circumvent
      // the buggy code from the lib (specifically: in the
      // "getValueForPhoneDigits" function).
      value = /\+/.test(phoneNumberHack)
        ? phoneNumberHack
        : "+1" + phoneNumberHack; // All troublesome cases start with +1
    }

    const parsed = parsePhoneNumber(value);

    // NB: some calling codes are used by several countries
    if (parsed) {
      const formattedNumber = normalizePhoneNr(parsed.number); // international number
      const isDifferentCountry = (parsed.country && parsed.country !== countryCode)
        || (parsed.countryCallingCode && parsed.countryCallingCode !== getCountryCallingCode(countryCode));

      if (isDifferentCountry) {
        // User inputted an international number with a different dial prefix so
        // we try to find a country with that calling code to update the flag.
        // NB: for countries sharing a dial code the parsing will guess the
        // country code but only if the full number was inputted.
        const newCountryCode = guessCountryCodeFromParsedNumber(parsed);
        if (newCountryCode) {
          setCountryCode(newCountryCode);

          // Wait for the country code to be updated before setting the phone number
          window.setTimeout(() => {
            setPhoneNumber(formattedNumber);
            onChange(formattedNumber);
          }, 1);
          return null;
        }
      }

      setPhoneNumber(formattedNumber);
      onChange(formattedNumber);
      return null;
    }

    setPhoneNumber(value);
    onChange(value); // Needed to update the input itself
  };

  const fieldId = props.fieldId || props.input.name || null;

  const phoneInputProps = {
    id: fieldId,
    required: !!props.required,
    name: props.input.name,
    placeholder: props.placeholder || t("phone_number_placeholder"),
    value: value,
    onChange: handlePhoneChange,
    onFocus: handlePhoneFocus,
    onBlur: handlePhoneBlur,
    type: "tel",
    autoComplete: "tel",
  }
  const phoneInput = (
    <PhoneInput ref={refInput}
      {...phoneInputProps}
      defaultCountry={countryCode} // Makes the input accept local numbers
    />
  );

  const telephoneInput = (
    <Select
      data={countriesMap}
      value={countryCode} // State's countryCode
      showBlank={false}
      valueKey={COUNTRY_CODE_KEY}
      titleKey={COUNTRY_LABEL_KEY}
      withSpritePath="/assets/flags/sprites-vertical.png"
      titleFromCode={titleFromCode}
      withInput={phoneInput}
      onChange={handleCountryChange}
      onFocus={handleCountryFocus}
      onBlur={handleCountryBlur}
      focused={focused}
      darkBackground={darkBackground}
      error={touched && error}
    />
  );

  if (props.mode === "table") {
    return (
      <tr className={styles.module + " field"}>
        <th>{label}</th>
        <td>
          { telephoneInput }
          <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
        </td>
      </tr>
    );
  }

  return (
    <div className={styles.module + " field"}>
      <div className={styles.label}>{label}
      {/* -- state:{phoneNumber} -- input: {value} */}
      </div>
      { telephoneInput }
      <FieldError serverError={serverError} clientError={error}
        showClientError={touched && error}
        fieldId={fieldId}
        darkBackground={darkBackground} />
    </div>
  );
}

TelephoneInputField.defaultProps = {
  active: true,
  mode: "table",
  darkBackground: false,
}

export default TelephoneInputField;
import React, {Component} from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";
import GraphQLErrors from "../shared/GraphQLErrors";
import {fromJS, List} from "immutable";
import Navigation, { buildProductImageLink } from "../lib/Navigation";
import {getProductIfInCart} from "../lib/ShopTools";
import {getMediaQuerySize} from "../reducers/SystemReducer";
import {NOTIFICATION_KINDS, showNotification, showNotificationWithPhoto} from "../actions/NotificationActions";
import {generateUUID} from "../lib/UUID";
import {
  requestAddWaterSubscriptionToCart,
  requestAddProductToCart,
  requestEditSubscriptionInCart,
  requestEditProductInCart,
  requestProductWaitingList
  } from "../actions/ShopActions";
import {showSupport} from "../actions/SupportActions";
import Divider from "./shared/Divider";
import Button from "../shared/components/Button";
import ProductListing from "../shared/components/ProductListing";
import { createErrors, createValidationErrors } from "../lib/ErrorFormatter";
import "./ShopPage.scss";
import ShopLayout from "./ShopLayout";
import ArrowButton from "./shared/icons/Arrow";
import {
  getNotificationForJurisdiction,
  getPrismicProductId,
  getProductForJurisdiction,
  getProductsForJurisdiction,
  productHasTag
  } from "../lib/ProductsInformation";
import withCms from "../hocs/withCms";
import { RichText } from "prismic-reactjs";
import NoSuchProduct from "../shared/components/NoSuchProduct";
import Banner from "../shared/components/Banner";
import { withTranslation } from 'react-i18next';
import { generateAddToCartSummary } from "../lib/StatsSummary";
import { recordAddToCart } from "../lib/Analytics";


class ShopPage extends Component {

  constructor(props) {
    super(props);
    this.handleWaterSubscriptionClick = this.handleWaterSubscriptionClick.bind(this);
    this.handleProductClick = this.handleProductClick.bind(this);

    this.state = {
      errors: null,
    }
  }

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountId && (prevProps.accountId !== this.props.accountId)) {
      this.subscribe();
    }
    if (this.props.data && !this.props.data.loading && this.props.data.customerCart) {
      let {customerCart} = prevProps.data;
      if (this.props.data.customerCart.allowedProductOrders === true && customerCart && (customerCart.allowedProductOrders !== true)) {
        this.props.showNotification(generateUUID(), this.props.t("dispensers_unavailable"));
      }
    }
  }

  subscribe() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.unsubscribe = this.props.subscribeToUpdates(this.props, {});
  }  

  handleSubscriptionClick() {
    Navigation.gotoShop(this.props.accountId);
  }

  render() {
    let {loading, error, customerCart, customerShopProducts, customerSubscriptionProducts} = this.props.data;
    let {cmsLoading, cmsData} = this.props;
    const baseClass = "ShopPage";

    if (cmsLoading || (loading && (!customerShopProducts || !customerSubscriptionProducts))) {
      return (
        <ShopLayout className={baseClass} showFooter={true}
          boundary={this.props.boundary} accountId={this.props.accountId}
          loading={true} />
      );
    }
    if (error) return <GraphQLErrors error={error} />

    const { accountId, productId } = this.props;

    const cart = fromJS(customerCart);
    const tip = buildTip(cmsData, cart);

    // We care about the country in the instashop only (for info on surcharges)
    // As long as productsPrices' country has the same code it's fine.
    const country = { code: "toot" }
    // build an object with same structure as the public API prices we use in the instashop
    const productsPrices = {
      country: country.code,
      currency: customerShopProducts[0].pricing[0].currency,
      products: customerShopProducts.map((serverP) => {
        return {
          unit_price: serverP.pricing[0].value,
          sku: serverP.productSku,
        }
      }),
      shop: customerCart.shopId,
      subscriptions: customerSubscriptionProducts.map((serverP) => {
        return {
          unit_price: serverP.pricing[0].value,
          sku: serverP.productSku,
        }
      }),
      surcharges: [], // Don't show potential surcharges for now even though we do in the instashop
    }

    // Get products from our CMS. Depending on the url, we could list only the
    // product with the given id, or given tag.
    const allPrismicProducts = getProductsForJurisdiction(cmsData, cart.get("shopId"));
    let prismicProducts = allPrismicProducts;
    let productsTagName = this.props.productsTagName;
    if (productId) {
      const prismicProduct = allPrismicProducts.find((p) => getPrismicProductId(p.get("product")) === productId);
      if (prismicProduct) {
        prismicProducts = List([prismicProduct]);
        // If the id is good there shouldn't be a tag name
        productsTagName = null;
      } else {
        // It might be a tag instead of an id, in which case we overwrite the tag name props
        productsTagName = productId;
      }
    }
    
    if (productsTagName) {
      prismicProducts = allPrismicProducts.filter((p) => productHasTag(p.get("product"), productsTagName)) || List([]);
    }
    
    // Link used to go back to the listing when in a product detail page
    const viewAllProductsLink = (
      <Button onClick={() => Navigation.gotoShop(accountId)}
        link theme={Button.THEMES.GOLD} className="backToShop">
        <ArrowButton arrowStyle={ArrowButton.STYLES.NORMAL} direction={ArrowButton.DIRECTIONS.LEFT} side="16px" />
        {this.props.t("back_to_shop")}
      </Button>
    );

    const content = prismicProducts.size > 0
      ? <ProductListing
          prismicProducts={prismicProducts}
          productsPrices={productsPrices}
          country={country}
          gotoProduct={(productId) => Navigation.gotoShopProduct(accountId, productId)}
          viewAllProductsLink={viewAllProductsLink}
          highlightIfAlone={!!productId} // For single product page
          cartAddProduct={this.handleProductClick}
          cartAddSubscription={this.handleWaterSubscriptionClick}
        />
      : <NoSuchProduct goToShop={() => Navigation.gotoShop(accountId)} />;

    return (
      <ShopLayout className={baseClass} showFooter={true}
        boundary={this.props.boundary} accountId={accountId}>
        { tip }

        { content }
        
        {/* TODO Hack for desktop so the cart footer doesn't overlap */}
        <div className="desktopOnly" style={{height: "88px"}} />
      </ShopLayout>
    );
  }

  setErrorsForSku(productSku, error) {
    const errors = {};
    errors[productSku] = error;
    this.setState({errors: errors});
  }

  showNotificationForAddedItems(productSku, productTitle, quantity) {
    this.props.showNotificationWithPhoto(
      generateUUID(),
      buildProductImageLink(productSku),
      [
        `${quantity}x ${productTitle}`,
        this.props.t("cart:added_items", {count: quantity})
      ],
      NOTIFICATION_KINDS.SUCCESS,
    );
  }

  handleWaterSubscriptionClick(productSku, productTitle, kind, status, quantity, price, currency) {
    if (status === "waiting-list") {
      this.handleWaitingListClick(kind, productSku, productTitle);
    } else {
      const onSuccess = () => {
        this.showNotificationForAddedItems(productSku, productTitle, quantity);
      }

      this.props.customerAddSubscriptionProductToCart({
        accountId: this.props.accountId,
        productSku: productSku,
        quantity: quantity,
      }).then((response) => {
        if (response.data.customerAddSubscriptionProductToCart.errors.length <= 0) {
          // Trigger analytics event.
          recordAddToCart(
            generateAddToCartSummary(productSku, productTitle, quantity, true, price, currency, this.props.data.customerCart.shopId).toJS()
          )
          onSuccess();
        } else {
          let errors = createValidationErrors(response.data.customerAddSubscriptionProductToCart.errors);
          this.setErrorsForSku(productSku, errors);
        }
      })
      .catch((err) => {
        this.setErrorsForSku(productSku, createErrors(err));
      });
    }
  }

  handleProductClick(productSku, productTitle, kind, status, quantity, price, currency) {
    if (status === "waiting-list") {
      this.handleWaitingListClick(kind, productSku, productTitle);
    } else {
      const productInCart = getProductIfInCart(fromJS(this.props.data.customerCart.items), productSku);

      if (quantity > 0) {
        // If we were provided a quantity, we add this many products to cart no
        // question asked.
        const onSuccess = () => {
          this.showNotificationForAddedItems(productSku, productTitle, quantity);
        }

        if (productInCart) {
          const currentQuantity = productInCart.get("quantity");
          this.props.customerChangeProductQuantityInCart({
            accountId: this.props.accountId,
            productSku: productSku,
            quantity: currentQuantity + quantity,
          }).then((response) => {
            if (response.data.customerChangeProductQuantityInCart.errors.length <= 0) {
              // Trigger analytics event.
              recordAddToCart(
                generateAddToCartSummary(productSku, productTitle, quantity, false, price, currency, this.props.data.customerCart.shopId).toJS()
              )
              onSuccess();
            } else {
              let errors = createValidationErrors(response.data.customerChangeProductQuantityInCart.errors);
              this.setErrorsForSku(productSku, errors);
            }
          })
          .catch((err) => {
            this.setErrorsForSku(productSku, createErrors(err));
          });
        }
        else {
          this.props.customerAddProductToCart({
            accountId: this.props.accountId,
            productSku: productSku,
            quantity: quantity,
          }).then((response) => {
            if (response.data.customerAddProductToCart.errors.length <= 0) {
              // Trigger analytics event.
              recordAddToCart(
                generateAddToCartSummary(productSku, productTitle, quantity, false, price, currency, this.props.data.customerCart.shopId).toJS()
              )
              onSuccess();
            } else {
              // this.props.showNotification(generateUUID(), "?")
              let errors = createValidationErrors(response.data.customerAddProductToCart.errors);
              this.setErrorsForSku(productSku, errors);
            }
          })
          .catch((err) => {
            this.setErrorsForSku(productSku, createErrors(err));
          });
        }
      }
      else {
        // Otherwise we display the Add/Edit forms
        if (productInCart) {
          this.props.requestEditProductInCart(this.props.accountId, productSku, productTitle);
        } else {
          this.props.requestAddProductToCart(this.props.accountId, productSku, productTitle);
        }
      }
    }
  }

  handleWaitingListClick(kind, productSku) {
    let cart = fromJS(this.props.data.customerCart);
    
    const product = getProductForJurisdiction(this.props.cmsData, cart.get("shopId"), productSku);
    const waitingListItem = {
      productSku: productSku,
      productTitle: product.get("long_title"),
      kind: kind === "subscription" ? kind : "product",
      waitingListText: <RichText render={product.get("waiting_list_note")} />,
    }
    this.props.requestProductWaitingList(cart.get("shopId"), this.props.accountId, waitingListItem);
  }

}

function buildTip(data, cart) {
  const shopId = cart.get("shopId");
  const notification = getNotificationForJurisdiction(data, shopId);

  const tip = notification && (
    <Banner type={notification.get("type")}>
      <RichText render={notification.get("text")} />
    </Banner>
  );
  
  return tip && (
    <div className="alerts">
      { tip }
      <Divider spaceAround={Divider.SPACES.SMALL} firstOrLast={true} />
    </div>
  );
}

const CART_INFO = gql`
  query customerCart($accountId:ID!) {
    customerShopProducts(accountId:$accountId) {
      shopId
      productSku
      productTitle
      pricing {
        value
        currency
      }      
    }
    customerSubscriptionProducts(accountId:$accountId) {
      shopId
      productSku
      productTitle
      pricing {
        value
        currency
      }
    }
    customerCart(accountId:$accountId) {
      shopId
      allowedSubscriptions
      allowedWaterOrders
      allowedProductOrders
      subscription
      items {
        productSku,
        quantity,
        subscription,
      }
    }
  }
`

const ON_UPDATED_CART = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedCart(accountId: $accountId) {
    accountId
  }`
);

const ADD_SUBSCRIPTION_PRODUCT_TO_CART = gql`
mutation customerAddSubscriptionProductToCart($data: CustomerAddSubscriptionProductToCartInput!) {
  customerAddSubscriptionProductToCart(input: $data) {
    errors { key message }
  }
}
`;

const ADD_PRODUCT_TO_CART = gql`
  mutation customerAddProductToCart($data: AddToCartInput!) {
    customerAddProductToCart(input: $data) {
      errors { key message }
    }
  }
`;
const EDIT_PRODUCT_IN_CART = gql`
  mutation customerChangeProductQuantityInCart($data: AddToCartInput!) {
    customerChangeProductQuantityInCart(input: $data) {
      errors { key message }
    }
  }
`;

const withQuery = compose(
  graphql(CART_INFO, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ADD_SUBSCRIPTION_PRODUCT_TO_CART, {
    props: ({ mutate }) => ({
      customerAddSubscriptionProductToCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(ADD_PRODUCT_TO_CART, {
    props: ({ mutate }) => ({
      customerAddProductToCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(EDIT_PRODUCT_IN_CART, {
    props: ({ mutate }) => ({
      customerChangeProductQuantityInCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);


const mapDispatchToProps = dispatch => ({
  subscribeToUpdates: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_CART,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestAddWaterSubscriptionToCart: (accountId, productSku) => {
    dispatch(requestAddWaterSubscriptionToCart(accountId, productSku));
  },
  requestEditSubscriptionInCart: (accountId, productSku) => {
    dispatch(requestEditSubscriptionInCart(accountId, productSku));
  },
  requestAddProductToCart: (accountId, productSku, productTitle) => {
    dispatch(requestAddProductToCart(accountId, productSku, productTitle));
  },
  requestEditProductInCart: (accountId, productSku, productTitle) => {
    dispatch(requestEditProductInCart(accountId, productSku, productTitle));
  },
  requestProductWaitingList: (shopId, accountId, item) => {
    dispatch(requestProductWaitingList(shopId, accountId, item));
  },
  showNotification: (id, message, kind) => {
    dispatch(showNotification(id, message, kind));
  },
  showNotificationWithPhoto: (id, photoUrl, message, kind) => {
    dispatch(showNotificationWithPhoto(id, photoUrl, message, kind));
  },
  showSupport: (page) => {
    dispatch(showSupport(page));
  }
})

const mapStateToProps = (state, ownProps) => {
  return {
    isXXLSize: getMediaQuerySize(state.get("system")) === "mq-xxl",
    isNarrowSize: getMediaQuerySize(state.get("system")) === "mq-sm",
    // Need more precision than media query size
    windowWidth: state.get("system").get("width"),
    accountId: ownProps.accountId,
  }
}

export default withTranslation("products")(connect(mapStateToProps, mapDispatchToProps)(withQuery(withCms(ShopPage))));

import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Form from "../forms/BillingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validateBillingAddress} from "../../lib/AddressValidation";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function validateForm(values, props) {
  return validateBillingAddress(values).toJS();
}

function SetupBillingAddressFormContainer(props) {
  const {
    data,
    upstreamErrors = [],
  } = props;
  const { t } = useTranslation("checkout");

  const [errors, setErrors] = useState(upstreamErrors);
  const [submitting, setSubmitting] = useState(false);

  function handleSubmit(values) {
    let updatedValues = applyStateProvinceRule({
      accountId: props.accountId,
      name: values.get("name"),
      addressAddressLine1: values.get("addressAddressLine1"),
      addressAddressLine2: values.get("addressAddressLine2", ""),
      addressCity: values.get("addressCity"),
      addressPostcode: values.get("addressPostcode"),
      addressStateProvince: values.get("addressStateProvince"),
      addressCountryCode: values.get("addressCountryCode")
    })

    setSubmitting(true);

    return props.customerChangeBillingAddress(updatedValues)
      .then((response) => {
        if (response.data.customerChangeBillingAddress.errors.length <= 0) {
          props.cancelChangeBillingAddress();
        } else {
          const errors = createValidationErrors(response.data.customerChangeBillingAddress.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  const countries = fromJS(filterAndSortCountries(data.countries));
  const account = fromJS(data.customerBillingAccount);
  let initialValues = {};
  if (account.get("addressStatus") === "provided") {
    initialValues = account.get("address").delete("__typename");
  }
  else {
    const assumedCountryCode = Cookies.get("assumed_country") || "";

    initialValues = fromJS({
      addressCountryCode: assumedCountryCode.toUpperCase(),
    });
    initialValues = initialValues.merge(props.prefilledAddress);
  }

  return (
    <Form
      initialValues={initialValues}
      submitting={submitting}
      submitLabel={t("billing.submit_address")}
      errors={errors}
      validate={validateForm}
      countries={countries}
      isCreation={true}
      onSubmit={handleSubmit}
      showCancel={false}
      showIntroText={false}
      />
  );
}


const GET_BILLING_ADDRESS = gql`
  query customerBillingAccount($accountId: ID!) {
    customerBillingAccount(accountId: $accountId) {
      address {
        name
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryCode
      }
      addressStatus
    }
    countries {
      code
      name
    }
  }
`;

const CHANGE_BILLING_ADDRESS = gql`
  mutation customerChangeBillingAddress($data: BillingAddressInput!) {
    customerChangeBillingAddress(input: $data) {
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(GET_BILLING_ADDRESS, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(CHANGE_BILLING_ADDRESS, {
    props: ({ mutate }) => ({
      customerChangeBillingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default withQueries(SetupBillingAddressFormContainer);


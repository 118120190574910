import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Conditional from "../../../shared/Conditional";
import Button from "../../../shared/components/Button";
import PromoCodeFormContainer from "../../forms/PromoCodeFormContainer";
import PromoCode from "../../shared/PromoCode";
import OrderSummary from "./OrderSummary";
import styles from "./PlaceOrderFixedPanel.module.scss";


export function PlaceOrderFixedPanel(props) {
  const {
    checkoutData,
    canPay,
    cmsDataProductTitles,
    onPlaceOrder,
    onCancelCheckout,
    onReload,
    payReminder = "",
    disablePromoCode = false,
  } = props;

  const { t } = useTranslation("checkout");

  const optionalPayReminder = (canPay || !payReminder)
    ? <p />
    : <div className={styles.notice}>{payReminder}</div>;

  return (
    <div className={styles.summary}>
      <div className={styles.fixedDesktop}>
        <img
          className={styles.logo}
          src="/assets/LogoSimple-Black.svg"
          alt="Hallstein - Artesian Water"
          width={159}
          height={66}
        />
        <Conditional show={!!checkoutData.get("products")}>
          <OrderSummary
            data={checkoutData} 
            productTitles={cmsDataProductTitles}
            />
        </Conditional>
        <div className={styles.divider} />
        <Conditional show={checkoutData.get("promoCodeApplied") === true && checkoutData.get("discount") === "--"}>
          <p>{t("summary.promo_code.discount_reminder")}</p>
        </Conditional>
        <div className={styles.PromoComponents}>
          <Conditional show={!disablePromoCode}>
            <PromoCodeFormContainer
              orderId={checkoutData.get("orderId")}
              accountId={checkoutData.get("accountId")}
              small={true}
            />
          </Conditional>

          <PromoCode
            active={checkoutData.get("promoCodeApplied") === true}
            code={checkoutData.get("promoCode")}
            />
        </div>
        <Conditional show={!!checkoutData.get("promoCode")}>
          <div className={styles.divider} />
        </Conditional>
        {optionalPayReminder}
        <div className={styles.buttons}>
          <Button label={t("summary.ok_label")}
            size={Button.SIZES.MEDIUM}
            onClick={() => onPlaceOrder(checkoutData)} active={canPay} />
          <Button label={t("summary.cancel_label")}
            visible={!!onCancelCheckout}
            onClick={onCancelCheckout}
            theme={Button.THEMES.WHITE} />
        </div>
        <div className={styles.more}>
          <p className={styles.stripeElement}>
            <span className={styles.text}>{t("summary.safe_and_secure")}</span>
            <span className={styles.icon} />
          </p>
          <p>
            <Trans
              i18nKey="checkout:summary.having_problems"
              components={{
                a: <Button link={true} onClick={onReload} />
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
import { useRive } from "@rive-app/react-canvas-lite";
import React from "react";

export default function WaterDisc(props) {
  const {
    rivProps,
    width = "170px",
    height = "170px",
  } = props

  const { RiveComponent } = useRive({
    src: '/assets/loading/water_disc.riv',
    animations: 'Liquid Load',
    autoplay: true,
  });

  const rivPropsWithAnimation = {
    ...rivProps,
  }

  const parentStyle = {
    marginInline: "auto",
    width: width,
    height: height,
  }

  return (
    <div {...props} style={parentStyle}>
      <RiveComponent {...rivPropsWithAnimation} animations="HoverOn" />
    </div>
  );
}
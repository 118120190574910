import React, {useState} from "react";
import Conditional from "../../../shared/Conditional";
import { StripeElements } from "../../../shop/views/Checkout/StripeElements";
import { FastCheckoutButtons } from "../../../shop/views/Checkout/FastCheckoutButtons";
import AnonymousRegistrationFormContainer from "./AnonymousRegistrationFormContainer";
import { useTranslation } from "react-i18next";
import { extractAddressesFromExpressCheckoutData } from "../../../lib/StripeHelper";
import { Map } from "immutable";
import Divider from "../../../shop/shared/Divider";
import styles from "../../AnonymousCheckoutPage.module.scss";
import { beginAnonymousCheckoutSaga } from "../../../actions/AnonymousCheckoutActions";
import { useDispatch } from "react-redux";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";

export const INFORMATION_ANCHORS = {
  ACCOUNT: "account",
  SHIPPING_ADDRESS: "shipping_address",
}

function StepInformation(props) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_information"});
  const dispatch = useDispatch();

  const {
    stripeClientSecret,
    gotoNextStep,
    gotoPreviousStep,
    anonymousCheckoutInfo = Map(),
    informationStatus,
    onInformationSuccess,
    onInformationError,
    updateAddresses,
  } = props;

  const shopId = anonymousCheckoutInfo.get("shopId");
  const accountId = anonymousCheckoutInfo.get("accountId"); // TODO do without? Saving the stripe addresses through the anonymous cart in the parent

  const [_retrievedInfoFromStripe, setRetrievedInfoFromStripe] = useState({});
  const [stripePaymentMethod, setStripePaymentMethod] = useState(null);
  const [_usedFastCheckout, setUsedFastCheckout] = useState(false);

  // For stripe prefilling data
  function handleDataRetrieval(stripeInfos) {
    const {shippingAddress, billingAddress, cardBrand} = extractAddressesFromExpressCheckoutData(stripeInfos)

    setRetrievedInfoFromStripe({
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      cardBrand: cardBrand,
    });

    updateAddresses(shippingAddress, billingAddress);
  }

  function handleSignIn(maybeEmailAddress) {
    dispatch(beginAnonymousCheckoutSaga(anonymousCheckoutInfo.get("cartId"), anonymousCheckoutInfo.get("accountId"), anonymousCheckoutInfo.get("countryCode"), false, true, true, maybeEmailAddress));
  }

  const fastCheckoutHeader = (
    <>
      <h1>{t("express_checkout.title")}</h1>
      <Divider style={{marginTop: "4px"}} />
    </>
  );
  const fastCheckoutFooter = (
    <>
      <Divider firstOrLast={true} style={{marginTop: "16px"}} />
      <p className="sg-text-tiny sg-grey-400">{t("express_checkout.notice")}</p>
      <Divider><b className="sg-text-normal u-no-margin">{t("express_checkout.or")}</b></Divider>
    </>
  );

  function handleSuccess(values) {
    gotoNextStep(values);
    onInformationSuccess();
  }

  return (
    <div className={styles.information}>
      <Conditional show={shopId && !!stripeClientSecret && !anonymousCheckoutInfo.get("onboardingId")}>
        <StripeElements
          shopId={shopId}
          clientSecret={stripeClientSecret}
          noIntent={true}>
          <FastCheckoutButtons
            withPanel={false}
            accountId={accountId}
            clientSecret={stripeClientSecret}
            onUse={() => setUsedFastCheckout(true)}
            onDataRetrieval={handleDataRetrieval}
            previousSiblings={fastCheckoutHeader}
            nextSiblings={fastCheckoutFooter}
            onStripePMReceived={setStripePaymentMethod}
          />
        </StripeElements>
      </Conditional>

      <AnonymousRegistrationFormContainer
        stripePaymentMethod={stripePaymentMethod}
        anonymousCheckoutInfo={anonymousCheckoutInfo}
        onSignIn={handleSignIn}
        onSuccess={handleSuccess}
        onError={onInformationError}
        onCancel={gotoPreviousStep}
        status={informationStatus}
      />
    </div>
  )
}


const SAVE_INFORMATION = gql`
  mutation anonymousCheckoutSaveInformation($data: StartOnboardingInput!) {
    anonymousCheckoutSaveInformation(input: $data) {
      onboardingId
      errors { key message }
    }
  }
`;

const withMutation = compose(
  graphql(SAVE_INFORMATION, {
    props: ({ mutate }) => ({
      anonymousCheckoutSaveInformation: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withMutation(StepInformation);

import React from "react";
import { useTranslation } from 'react-i18next';
import styles from "./Breadcrumb.module.scss";
import ArrowButton from "../../shop/shared/icons/Arrow";
import { CHECKOUT_STEPS } from "../AnonymousCheckoutPage";


export function Breadcrumb(props) {
  const {
    currentStep = CHECKOUT_STEPS.STEP_INFORMATION,
    step1Active = true,
    step2Active = false,
    className,
    gotoCart,
    gotoInformationStep,
    gotoPaymentStep,
  } = props;

  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.breadcrumb"});

  const steps = [
    {
      label: t("cart"),
      onClick: gotoCart,
      active: true,
      pending: false,
      current: false, // In instashop
    },
    {
      label: t("information"),
      onClick: gotoInformationStep,
      active: step1Active,
      pending: false,
      current: currentStep === CHECKOUT_STEPS.STEP_INFORMATION,
    },
    {
      label: t("payment"),
      onClick: gotoPaymentStep,
      active: step2Active,
      pending: true, // Always pending as user exits it when done
      current: currentStep === CHECKOUT_STEPS.STEP_PAYMENT,
    },
  ]

  const classes = [styles.breadcrumb, className].join(" ");
  const arrow = (
    <ArrowButton className={styles.arrow}
      direction={ArrowButton.DIRECTIONS.RIGHT} arrowStyle={ArrowButton.STYLES.LINE}
    />
  )

  return (
    <ol className={classes}>
      {steps.map((step, index) => {
        const interStep = index === 0 ? null : arrow;

        return (
          <React.Fragment key={index}>
            {interStep}
            <Step {...step} />
          </React.Fragment>
        );
      })}
    </ol>
  )
}

function Step({label, active, pending, current, onClick}) {
  const classes = [
    styles.step,
    active ? styles.active : "",
    current ? styles.current : "",
    pending ? styles.pending : "",
  ].join(" ");
  
  return (
    <li className={classes}><button onClick={() => active && onClick()}>{label}</button></li>
  )
}
import React from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { FastCheckoutButtons } from "./FastCheckoutButtons";


export function FastCheckoutButtonsContainer(props) {
  const {
    accountId,
  } = props;

  // Mutation to register PM into the system for later use
  const [customerAddPaymentMethod, {error: mutationError}] = useMutation(ADD_PAYMENT_METHOD);
  
  function handleStripePMReceived(stripePM) {
    // save stripe payment method id
    customerAddPaymentMethod({
      variables: {
        data: {
          accountId: accountId,
          paymentMethodId: stripePM,
        }
      }
    });
  }

  return (
    <FastCheckoutButtons {...props}
      onStripePMReceived={handleStripePMReceived}
      mutationError={mutationError}
    />
  );
}

const ADD_PAYMENT_METHOD = gql`
  mutation customerAddPaymentMethod($data: PaymentMethodIdInput!) {
    customerAddPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`;
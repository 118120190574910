import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Conditional from "../../shared/Conditional";
import OrderSummary from "../../shop/views/Checkout/OrderSummary";
import ArrowButton from "../../shop/shared/icons/Arrow";
import styles from "../AnonymousCheckoutPage.module.scss";


export function MobileOrderSummary({checkoutData, cmsDataProductTitles}) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.mobile_order_summary"});

  const [showMobileOrderSummary, setShowMobileOrderSummary] = useState(false);
  function toggle() {
    setShowMobileOrderSummary(!showMobileOrderSummary);
  }

  const summary = showMobileOrderSummary && (
    <div className={styles.mobileOrderSummary}>
      <Conditional show={!!checkoutData.get("products")}>
        <OrderSummary displayTitle={false} data={checkoutData} productTitles={cmsDataProductTitles} />
      </Conditional>
    </div>
  )
  
  return (
    <div className={styles.mobileOrderSummaryParent}>
      <div className={styles.header} onClick={toggle}>
        <div className={styles.title}>{showMobileOrderSummary ? t("hide") : t("show")}</div>
        <div className={styles.arrow}>
          <ArrowButton arrowStyle={ArrowButton.STYLES.DROP}
            direction={showMobileOrderSummary ? ArrowButton.DIRECTIONS.UP : ArrowButton.DIRECTIONS.DOWN}
            colorDisabled="var(--primary-blue-700)"
          />
        </div>
        <div className={styles.total}>--</div>
      </div>
      {summary}
    </div>
  )
}

import React from "react";
import { useTranslation } from "react-i18next";
import InformationStepSummary from "./InformationStepSummary";
import Divider from "../../../shop/shared/Divider";
import { Map } from "immutable";
import Button from "../../../shared/components/Button";
import styles from "../../AnonymousCheckoutPage.module.scss";
import { PaymentMethod } from "./PaymentMethod";
import { BillingAddress } from "./BillingAddress";
import PromoCodeFormContainer from "./PromoCodeFormContainer";


export default function StepPayment(props) {
  const { t } = useTranslation("checkout", {keyPrefix: "anonymous_checkout.step_payment"});

  const {
    anonymousCheckoutInfo = Map(),
    gotoNextStep,
    gotoPreviousStep,
    submitting = false,
    canPay = false,
    stripeStatus,
  } = props;

  const submitLabel = submitting ? t("common:standby") : t("continue");

  return (
    <div className={styles.payment}>
      <InformationStepSummary email={anonymousCheckoutInfo.getIn(["shippingAddress", "email"])}
        shippingAddress={anonymousCheckoutInfo.get("shippingAddress")}
        gotoPreviousStep={gotoPreviousStep}
      />
      <Divider firstOrLast={true} />

      <h1>{t("payment.title")}</h1>
      <TitleDivider />

      <PaymentMethod {...props} status={stripeStatus} />

      <Divider firstOrLast={true} />
      
      <h1>{t("billing_address.title")}</h1>
      <TitleDivider />
      <p className="sg-text-small">{t("billing_address.notice")}</p>

      <BillingAddress {...props} className={styles.billingAddress} />

      <Divider firstOrLast={true} />
      
      <h1>{t("promo_code.title")}</h1>
      <Divider style={{marginTop: "8px", marginBottom: "24px"}} />

      <PromoCodeFormContainer {...props} />

      <div className={styles.buttons}>
        <Button onClick={gotoNextStep}
          visible={!!gotoNextStep}
          size={Button.SIZES.MEDIUM}
          primary={true} label={submitLabel} active={canPay}
        />

        <Button onClick={() => gotoPreviousStep()} link
          theme={Button.THEMES.GREY_OUTLINE}>
          {t("return")}
        </Button>
      </div>
    </div>
  )
}

function TitleDivider() {
  return <Divider style={{marginTop: "8px", marginBottom: "12px"}} />
}